import React, { useState } from 'react';
import { useSubscriptionStatus } from '../hooks/subscription';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const SubscriptionManagement = () => {
  const { currentUser } = useAuth();
  const { 
    isLoading, 
    isActive, 
    isTrialing, 
    trialEnd,
    error 
  } = useSubscriptionStatus();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelError, setCancelError] = useState('');

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-AU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getSubscriptionPortal = async () => {
    try {
      // Create a billing portal session
      const portalRef = doc(db, 'customers', currentUser.uid, 'checkout_sessions', 'create-portal');
      const portalDoc = await getDoc(portalRef);
      
      if (portalDoc.exists() && portalDoc.data().url) {
        window.location.assign(portalDoc.data().url);
      } else {
        throw new Error('No portal URL found');
      }
    } catch (error) {
      console.error('Error accessing billing portal:', error);
      setCancelError('Unable to access billing portal. Please try again later.');
    }
  };

  if (isLoading) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <div className="text-red-500">
          Error loading subscription details: {error}
        </div>
      </div>
    );
  }

  if (!isActive && !isTrialing) {
    return (
      <div className="p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Subscription Status</h2>
        <div className="mb-4">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
            Inactive
          </span>
        </div>
        <p className="text-gray-600 mb-4">
          You currently don't have an active subscription.
        </p>
        <button
          onClick={() => window.location.href = '/subscription'}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
        >
          Subscribe Now
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Subscription Status</h2>
      
      <div className="space-y-4">
        <div>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
            {isTrialing ? 'Trial Active' : 'Active'}
          </span>
        </div>

        <div className="grid gap-4">
          <div>
            <p className="text-sm font-medium text-gray-500">Plan</p>
            <p className="mt-1">Professional Plan - $89/month AUD</p>
          </div>

          {isTrialing && trialEnd && (
            <div>
              <p className="text-sm font-medium text-gray-500">Trial Period</p>
              <p className="mt-1">
                Trial ends on {formatDate(trialEnd)}
              </p>
              <p className="text-sm text-gray-500 mt-1">
                Your card will be charged after the trial ends
              </p>
            </div>
          )}

          <div>
            <p className="text-sm font-medium text-gray-500">Features</p>
            <ul className="mt-1 list-disc list-inside text-gray-600">
              <li>Unlimited inventory items</li>
              <li>Unlimited sales orders</li>
              <li>Unlimited purchase orders</li>
              <li>Xero integration</li>
              <li>Priority support</li>
            </ul>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200">
          <button
            onClick={getSubscriptionPortal}
            disabled={cancelLoading}
            className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-900 transition-colors disabled:opacity-50"
          >
            {cancelLoading ? 'Loading...' : 'Manage Subscription'}
          </button>
          
          {cancelError && (
            <p className="mt-2 text-sm text-red-600">{cancelError}</p>
          )}
          
          <p className="mt-2 text-sm text-gray-500">
            Manage your subscription, update payment method, or view billing history
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagement;
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SalesPurchasesChart = ({ salesData, purchaseData }) => {
  const options = {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: true
      },
      toolbar: {
        show: true
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
          day: 'dd MMM'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    colors: ['#2E93fA', '#66DA26'],
    legend: {
      show: true,
      position: 'top'
    }
  };

  const series = [
    {
      name: 'Sales',
      data: salesData
    },
    {
      name: 'Purchases',
      data: purchaseData
    }
  ];

  return (
    <div>
      {salesData.length > 0 || purchaseData.length > 0 ? (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      ) : (
        <div className="no-data">No data available for selected date range</div>
      )}
    </div>
  );
};

export default SalesPurchasesChart;
import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

export const useSubscriptionStatus = () => {
  const { currentUser } = useAuth();
  const [status, setStatus] = useState({
    isLoading: true,
    isActive: false,
    isTrialing: false,
    trialEnd: null,
    error: null
  });

  useEffect(() => {
    if (!currentUser) {
      setStatus(prev => ({ ...prev, isLoading: false }));
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'users', currentUser.uid),
      (snapshot) => {
        try {
          if (!snapshot.exists()) {
            setStatus({
              isLoading: false,
              isActive: false,
              isTrialing: false,
              trialEnd: null,
              error: null
            });
            return;
          }

          const data = snapshot.data();
          const now = new Date();
          const trialEnd = data.trialEnd?.toDate();
          const isTrialing = trialEnd ? now < trialEnd : false;
          
          setStatus({
            isLoading: false,
            isActive: data.status === 'active',
            isTrialing,
            trialEnd,
            error: null
          });
        } catch (error) {
          console.error('Error processing subscription data:', error);
          setStatus(prev => ({
            ...prev,
            isLoading: false,
            error: 'Error loading subscription status'
          }));
        }
      },
      (error) => {
        console.error('Error fetching subscription:', error);
        setStatus(prev => ({
          ...prev,
          isLoading: false,
          error: 'Error loading subscription status'
        }));
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  return status;
};
import React, { useState } from 'react';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
// In your Payment component:
import { SUBSCRIPTION_ROUTES, TRIAL_PERIOD_DAYS } from '../utils/subscription/constants';

const Payment = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const createCheckoutSession = async () => {
    if (!currentUser) {
      setError('Please sign in to continue');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // First, verify we can create the path correctly
      const customerPath = `customers/${currentUser.uid}/checkout_sessions`;
      console.log('Creating checkout session at path:', customerPath);

      const checkoutSessionRef = collection(db, 'customers', currentUser.uid, 'checkout_sessions');

      // Log the session data we're about to create
      const sessionData = {
        price: 'price_1Q9H5RGS89Y15uQTo2wb7e10', // Replace with your actual Stripe Price ID
        success_url: 'https://oneventory.app//subscription/success',
        cancel_url: 'https://oneventory.app//subscription/error',
        allow_promotion_codes: true,
        mode: 'subscription',
        metadata: {
          userId: currentUser.uid,
        },
        currency: 'AUD'
      };

      console.log('Creating session with data:', sessionData);

      const docRef = await addDoc(checkoutSessionRef, sessionData);
      console.log('Checkout session doc created:', docRef.id);

      // Set a timeout to prevent infinite loading
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setError('Checkout session creation timed out. Please try again.');
      }, 10000);

      // Listen for changes
      const unsubscribe = onSnapshot(docRef, 
        (snap) => {
          const data = snap.data();
          console.log('Snapshot data:', data);

          if (data) {
            clearTimeout(timeoutId);
            
            if (data.error) {
              console.error('Checkout session error:', data.error);
              setError(data.error.message);
              setLoading(false);
            }

            if (data.url) {
              console.log('Redirecting to:', data.url);
              window.location.assign(data.url);
            }
          }
        },
        (error) => {
          console.error('Snapshot error:', error);
          clearTimeout(timeoutId);
          setError('Failed to monitor checkout session. Please try again.');
          setLoading(false);
        }
      );

      // Clean up listener if component unmounts
      return () => {
        unsubscribe();
        clearTimeout(timeoutId);
      };

    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError('Failed to initiate checkout. Please check console for details.');
      setLoading(false);
    }
  };

  return (
    <div className="payment-container">
      <h1>Complete Your Subscription</h1>
      
      {error && (
        <div className="error-message">
          {error}
          {loading && <p>You can refresh the page and try again.</p>}
        </div>
      )}

      <div className="subscription-details">
        <h2>Professional Plan</h2>
        <div className="pricing">
          <p className="price">$89/month AUD</p>
          <i className="trial">with 10 Days Free Trial</i>
        </div>
        <ul>
          <li>Unlimited inventory items</li>
          <li>Unlimited sales orders</li>
          <li>Unlimited purchase orders</li>
          <li>Xero integration</li>
          <li>Priority support</li>
        </ul>
        <p>
          Start your 10-day free trial today. You won't be charged until your trial ends.
        </p>
        <i>Cancel anytime during the trial period.</i>
      </div>

      <button
        onClick={createCheckoutSession}
        disabled={loading}
        className={`checkout-button ${loading ? 'loading' : ''}`}
      >
        {loading ? (
          <>
            <span className="spinner"></span>
            Processing...
          </>
        ) : (
          'Start Free Trial'
        )}
      </button>

      {loading && (
        <p className="loading-message">
          Creating your checkout session... Please wait.
        </p>
      )}
    </div>
  );
};

export default Payment;
import { collection, doc, increment } from 'firebase/firestore';

export async function getInventoryUpdates(transaction, orderData, companyId, db) {
  const { items } = orderData;
  const inventoryUpdates = [];

  for (const item of items) {
    const inventoryRef = doc(collection(db, 'inventory'), item.id);
    const inventoryDoc = await transaction.get(inventoryRef);

    if (!inventoryDoc.exists()) {
      console.warn(`Inventory item ${item.id} not found. Skipping update.`);
      continue;
    }

    const inventoryData = inventoryDoc.data();

    if (inventoryData.companyId !== companyId) {
      console.warn(`Inventory item ${item.id} does not belong to the correct company. Skipping update.`);
      continue;
    }

    const newQuantity = inventoryData.quantity + item.quantity;
    const newCostPrice = (inventoryData.quantity * inventoryData.cost_price + item.quantity * item.price) / newQuantity;

    inventoryUpdates.push({
      ref: inventoryRef,
      newQuantity,
      newCostPrice
    });
  }

  return inventoryUpdates;
}

export function applyInventoryUpdates(transaction, inventoryUpdates) {
  for (const update of inventoryUpdates) {
    transaction.update(update.ref, {
      quantity: update.newQuantity,
      cost_price: update.newCostPrice
    });
  }
}
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, getDoc, updateDoc, deleteDoc, doc , query, where, writeBatch} from 'firebase/firestore';
import Papa from 'papaparse';
import { useAuth } from '../contexts/AuthContext';
import getCompanyIdForUser from '../utils/getCompanyIdForUser'; 
import EnhancedOrderSidebar from '../components/OrderSidebar/EnhancedOrderSidebar';
import TableHeader from './TableHeader';
import { ArrowBigUp, ArrowBigDown, Trash, Edit, Package, Info} from 'lucide-react';


function Inventory() {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [newItem, setNewItem] = useState({ name: '', quantity: 0, cost_price: 0, sell_price: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [salesOrder, setSalesOrder] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [adjustingStock, setAdjustingStock] = useState(null);
  const [sidebarType, setSidebarType] = useState('');
  const [orderQuantities, setOrderQuantities] = useState({});
  const [editingItem, setEditingItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [importProgress, setImportProgress] = useState(0);
  const [importLogs, setImportLogs] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: '',
    dueDate: '',
    customerSupplierName: ''
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentCompany, setCurrentCompany] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const { currentUser } = useAuth();
  const [settings, setSettings] = useState({ moderateThreshold: 0, lowThreshold: 0 });

  useEffect(() => {
    if (currentUser) {
      fetchCompanyId();
    }
  }, [currentUser]);

  useEffect(() => {
    if (companyId) {
      fetchItems();
    }
  }, [companyId]);


  useEffect(() => {
    fetchSettings();
    const results = items.filter(item =>
      (item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       item.sku.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (categoryFilter === '' || item.category === categoryFilter)
    );
    setFilteredItems(results);
  }, [items, searchTerm, categoryFilter]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const downloadSampleCSV = () => {
    const csvContent = "SKU,Name,Category,Quantity,Cost Price,Sell Price,Location\n" +
                       "SAMPLE001,Sample Item 1,Category A,10,5.00,10.00,Warehouse A\n" +
                       "SAMPLE002,Sample Item 2,Category B,20,7.50,15.00,Warehouse B";
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sample_inventory.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (result) => {
        setImportedData(result.data);
        setImportLogs([`Parsed ${result.data.length} items from CSV`]);
      },
      header: true
    });
  };

  const importItems = async () => {
    setIsImporting(true);
    setImportProgress(0);
    setImportLogs([]);

    const batchSize = 20;
    const delayBetweenBatches = 1000; // 1 second delay between batches
    let processed = 0;

    for (let i = 0; i < importedData.length; i += batchSize) {
      const batch = writeBatch(db);
      const batchItems = importedData.slice(i, i + batchSize);

      for (let item of batchItems) {
        const skuQuery = query(collection(db, 'inventory'), where('sku', '==', item.SKU), where('companyId', '==', companyId));
        const querySnapshot = await getDocs(skuQuery);

        if (querySnapshot.empty) {
          // SKU doesn't exist, create new item
          const newDocRef = doc(collection(db, 'inventory'));
          batch.set(newDocRef, {
            sku: item.SKU,
            name: item.Name,
            category: item.Category,
            quantity: parseInt(item.Quantity),
            cost_price: parseFloat(item['Cost Price']),
            sell_price: parseFloat(item['Sell Price']),
            location: item.Location,
            companyId: companyId
            //companyId : "JV5CTCUZTLEytVYsnZ3m"
          });
          setImportLogs(prev => [...prev, `Creating new item: ${item.SKU}`]);
        } else {
          // SKU exists, update item
          const docRef = querySnapshot.docs[0].ref;
          batch.update(docRef, {
            name: item.Name,
            category: item.Category,
            quantity: parseInt(item.Quantity),
            cost_price: parseFloat(item['Cost Price']),
            sell_price: parseFloat(item['Sell Price']),
            location: item.Location,
            companyId : companyId
            //companyId: String(fetchCompanyId()) || null
          });
          setImportLogs(prev => [...prev, `Updating existing item: ${item.SKU}`]);
        }
      }

      await batch.commit();
      processed += batchItems.length;
      setImportProgress((processed / importedData.length) * 100);
      setImportLogs(prev => [...prev, `Processed batch: ${processed}/${importedData.length}`]);

      if (i + batchSize < importedData.length) {
        await new Promise(resolve => setTimeout(resolve, delayBetweenBatches));
      }
    }

    setImportLogs(prev => [...prev, 'Import completed']);
    setIsImporting(false);
    setImportedData([]);
    fetchItems();
  };

  const fetchUserCompanies = async () => {
    const userDoc = await getDocs(doc(db, 'users', currentUser.uid));
    const userData = userDoc.data();
    if (userData.companies && userData.companies.length > 0) {
      setCurrentCompany(userData.companies[0]);  // Set to first company for now
    }
  };

  const fetchCompanyId = async () => {
    const id = await getCompanyIdForUser(currentUser.uid);
    if (id) {
      setCompanyId(id);
    } else {
      setError("Failed to fetch company ID. Please try again.");
    }
  };


  /** , where('companyId', '==', currentUser.uid)  */
  const fetchItems = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, 'inventory'), where('companyId', '==', companyId));
      const querySnapshot = await getDocs(q);
      const fetchedItems = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setItems(fetchedItems);
      
      const uniqueCategories = [...new Set(fetchedItems.map(item => item.category))];
      setCategories(uniqueCategories);
    } catch (err) {
      console.error("Error fetching items: ", err);
      setError("Failed to fetch inventory items. Please try again.");
    }
    setLoading(false);
  };

  const addItem = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'inventory'), {
        ...newItem,
        companyId: currentUser.uid,
        quantity: parseInt(newItem.quantity),
        cost_price: parseFloat(newItem.cost_price),
        sell_price: parseFloat(newItem.sell_price),
        companyId : companyId 
      });
      setNewItem({ name: '', sku: '', quantity: 0, cost_price: 0, sell_price: 0, location: '', category: '' });
      fetchItems();
    } catch (err) {
      console.error("Error adding item: ", err);
      setError("Failed to add item. Please try again.");
    }
  };

  const updateItem = async (id, updatedFields) => {
    try {
      await updateDoc(doc(db, 'inventory', id), updatedFields);
      fetchItems();
    } catch (err) {
      console.error("Error updating item: ", err);
      setError("Failed to update item. Please try again.");
    }
  };

  const fetchSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', currentUser.uid));
      if (settingsDoc.exists()) {
        setSettings(settingsDoc.data());
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleEdit = (item) => {
    setEditingItem({ ...item });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingItem(prev => ({ ...prev, [name]: name === 'quantity' ? parseInt(value) : parseFloat(value) }));
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    updateItem(editingItem.id, editingItem);
  };


  const deleteItem = async (id, itemName) => {
    // Show confirmation popup
    const isConfirmed = window.confirm(`Are you sure you want to delete the item "${itemName}"? This action cannot be undone.`);
  
    if (isConfirmed) {
      try {
        await deleteDoc(doc(db, 'inventory', id));
        fetchItems();
        // Optionally, show a success message
        alert(`Item "${itemName}" has been successfully deleted.`);
      } catch (err) {
        console.error("Error deleting item: ", err);
        setError(`Failed to delete item "${itemName}". Please try again.`);
      }
    } else {
      // If user cancels, optionally show a cancellation message
      console.log("Deletion cancelled by user.");
    }
  };

  const handleAdjustStock = (item) => {
    setAdjustingStock({ ...item, newQuantity: item.quantity });
  };

  const handleAdjustmentChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setAdjustingStock(prev => ({ ...prev, newQuantity }));
  };

  const handleAdjustmentSubmit = (e) => {
    e.preventDefault();
    updateItem(adjustingStock.id, { quantity: adjustingStock.newQuantity });
    setAdjustingStock(null);
  };

  const formatPrice = (price) => {
    const numPrice = Number(price);
    return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2);
  };

  const handleQuantityChange = (itemId, value) => {
    setOrderQuantities(prev => ({
      ...prev,
      [itemId]: Math.max(0, parseInt(value) || 0)
    }));
  };

  const addToOrder = (item, orderType) => {
    const quantity = orderQuantities[item.id] || 1;  // Default to 1 if not set
    if (quantity < 1) return;

    const order = orderType === 'sales' ? salesOrder : purchaseOrder;
    const setOrder = orderType === 'sales' ? setSalesOrder : setPurchaseOrder;
    
    setOrder({
      ...order,
      [item.id]: (order[item.id] || 0) + quantity
    });

    setSidebarType(orderType);
    setShowSidebar(true);

    // Reset the order quantity for this item
    setOrderQuantities(prev => ({...prev, [item.id]: 0}));
  };

  const handleUpdateOrder = (updatedOrder, type) => {
    if (type === 'sales') {
      setSalesOrder(updatedOrder);
    } else {
      setPurchaseOrder(updatedOrder);
    }
  };

  const removeFromOrder = (itemId, type) => {
    if (type === 'sales') {
      setSalesOrder(prev => {
        const newOrder = { ...prev };
        delete newOrder[itemId];
        return newOrder;
      });
    } else {
      setPurchaseOrder(prev => {
        const newOrder = { ...prev };
        delete newOrder[itemId];
        return newOrder;
      });
    }
  };

  const handleOrderDetailsChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const getStockLevelClass = (quantity) => {
    if (quantity <= settings.lowThreshold) return 'low-stock';
    if (quantity <= settings.moderateThreshold) return 'moderate-stock';
    return '';
  };

  const getStockLevelText = (quantity) => {
    if (quantity <= settings.lowThreshold) return 'Low';
    if (quantity <= settings.moderateThreshold) return 'Moderate';
    return 'Normal';
  };

  const createOrder = async () => {
    const order = sidebarType === 'sales' ? salesOrder : purchaseOrder;
    const orderItems = items.filter(item => order[item.id]).map(item => ({
      id: item.id,
      name: item.name,
      quantity: order[item.id],
      price: sidebarType === 'sales' ? item.sell_price : item.cost_price
    }));
  
    const newOrder = {
      orderId: orderDetails.orderId,
      [sidebarType === 'sales' ? 'customerName' : 'supplierName']: orderDetails.customerSupplierName,
      dueDate: orderDetails.dueDate,
      userId: currentUser.uid,
      createdAt: new Date().toISOString(),
      status: 'pending',
      totalAmount: orderItems.reduce((total, item) => total + (item.quantity * item.price), 0),
      items: orderItems,
      companyId: companyId
    };
  
    try {
      // Start a batch write to update both order and inventory
      const batch = writeBatch(db);
  
      // Add the order
      const orderRef = doc(collection(db, `${sidebarType}Orders`));
      batch.set(orderRef, newOrder);
  
      // Update inventory quantities
      for (const orderItem of orderItems) {
        const inventoryRef = doc(db, 'inventory', orderItem.id);
        const inventoryDoc = await getDoc(inventoryRef);
        
        if (inventoryDoc.exists()) {
          const currentQuantity = inventoryDoc.data().quantity;
          // Decrease quantity for sales, increase for purchases
          const newQuantity = currentQuantity + (sidebarType === 'sales' ? -orderItem.quantity : orderItem.quantity);
          
          // Optional: Check for negative stock in sales orders
          if (sidebarType === 'sales' && newQuantity < 0) {
            throw new Error(`Insufficient stock for item: ${orderItem.name}`);
          }
  
          batch.update(inventoryRef, { quantity: newQuantity });
        }
      }
  
      // Commit all changes
      await batch.commit();
  
      // Success handling
      alert(`${sidebarType.charAt(0).toUpperCase() + sidebarType.slice(1)} order created successfully!`);
      
      // Reset the orders and details
      if (sidebarType === 'sales') {
        setSalesOrder({});
      } else {
        setPurchaseOrder({});
      }
      setOrderDetails({ orderId: '', dueDate: '', customerSupplierName: '' });
      setShowSidebar(false);
      
      // Refresh inventory
      fetchItems();
  
      return { id: orderRef.id, ...newOrder };
    } catch (err) {
      console.error(`Error creating ${sidebarType} order:`, err);
      if (err.message.includes('Insufficient stock')) {
        alert(err.message);
      } else {
        alert(`Failed to create ${sidebarType} order. Please try again.`);
      }
      throw err;
    }
  };
  

  if (loading) return <div>Loading inventory...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="inventory-container">
      <h1>Inventory</h1>

      <div className="filter-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search inventory by name, SKU or location"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      <table className="inventory-table">
        <TableHeader />
        <tbody>
          {filteredItems.map(item => {
            const margin = item.sell_price - item.cost_price;
            const marginPercentage = (margin / item.cost_price) * 100;
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.sku}</td>
                <td>{item.category}</td>
                <td className={getStockLevelClass(item.quantity)}>{item.quantity}</td>
                <td>${formatPrice(item.cost_price)}</td>
                <td>${formatPrice(item.sell_price)}</td>
                <td>${formatPrice(margin)}</td>
                <td>{marginPercentage.toFixed(2)}%</td>
                <td>{item.location}</td>
                <td className="actions-cell" colSpan="2">
                  <div className="quantity-control">
                    <input
                      type="number"
                      value={orderQuantities[item.id] || 1}
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                      min="1"
                    />
                  </div>
                  <button onClick={() => addToOrder(item, 'sales')}>
                    <ArrowBigUp size={24} alt="Add to Sales Order"/>Add to Sales Order
                  </button>
                  <button onClick={() => addToOrder(item, 'purchase')}>
                    <ArrowBigDown size={24} alt="Add to Purchase Order"/>Add to Purchase Order
                  </button>
                  <button onClick={() => deleteItem(item.id, item.name)}>
                    <Trash size={22} alt="Delete"/>Delete
                  </button>
                  <button onClick={() => handleEdit(item)}>
                    <Edit size={20} alt="Edit"/>Edit
                  </button>
                  <button onClick={() => handleAdjustStock(item)}>
                    <Package size={22} alt="Adjust Stock"/>Adjust Stock
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}><strong>Totals</strong></td>
          <td>{filteredItems.reduce((total, item) => parseFloat(total) + parseFloat(item.quantity), 0)}</td>
          <td>${formatPrice(filteredItems.reduce((total, item) => total + item.cost_price * item.quantity, 0))}</td>
          <td>${formatPrice(filteredItems.reduce((total, item) => total + item.sell_price * item.quantity, 0))}</td>
          <td>${formatPrice(filteredItems.reduce((total, item) => total + (item.sell_price - item.cost_price) * item.quantity, 0))}</td>
          <td>
            {((filteredItems.reduce((total, item) => total + (item.sell_price - item.cost_price) * item.quantity, 0) /
              filteredItems.reduce((total, item) => total + item.cost_price * item.quantity, 0)) * 100).toFixed(2)}%
          </td>
          <td colSpan={2}></td>
        </tr>
      </tfoot>
    </table>


    <div className="add-item-container">
        <button onClick={() => setShowAddForm(!showAddForm)} className="toggle-add-form">
          {showAddForm ? 'Hide Add Item' : 'Add Item'}
        </button>
        <div className="import-export">
          <button onClick={downloadSampleCSV}>Download Sample CSV</button>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
          <button onClick={importItems} disabled={importedData.length === 0}>
            Import Items
          </button>
        </div>
        {showAddForm && (
          <form onSubmit={addItem} className="add-item-form">
            <div className="form-group">
              <label htmlFor="item-name">Item Name:</label> <br></br>
              <input
                id="item-name"
                type="text"
                value={newItem.name}
                onChange={(e) => setNewItem({...newItem, name: e.target.value})}
                placeholder="Enter item name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="item-name">Item SKU:</label> <br></br>
              <input
                type="text"
                value={newItem.sku}
                onChange={(e) => setNewItem({...newItem, sku: e.target.value})}
                placeholder="SKU"
                required
              />
            </div>


            <div className="form-group">
              <label htmlFor="item-quantity">Quantity:</label><br></br>
              <input
                id="item-quantity"
                type="number"
                value={newItem.quantity}
                onChange={(e) => setNewItem({...newItem, quantity: e.target.value})}
                placeholder="Enter quantity"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="item-cost-price">Cost Price:</label><br></br>
              <div className="price-input">
                <span className="dollar-sign">$</span>
                <input
                  id="item-cost-price"
                  type="number"
                  value={newItem.cost_price}
                  onChange={(e) => setNewItem({...newItem, cost_price: e.target.value})}
                  placeholder="0.00"
                  required
                  step="0.01"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="item-sell-price">Sell Price:</label><br></br>
              <div className="price-input">
                <span className="dollar-sign">$</span>
                <input
                  id="item-sell-price"
                  type="number"
                  value={newItem.sell_price}
                  onChange={(e) => setNewItem({...newItem, sell_price: e.target.value})}
                  placeholder="0.00"
                  required
                  step="0.01"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="item-sell-price">Location: </label><br></br>
              <div className="price-input">
                <input
                  type="text"
                  value={newItem.location}
                  onChange={(e) => setNewItem({...newItem, location: e.target.value})}
                  placeholder="Location"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="item-sell-price">Category: </label><br></br>
              <div className="price-input">
              <input
                type="text"
                value={newItem.category}
                onChange={(e) => setNewItem({...newItem, category: e.target.value})}
                placeholder="Category"
                required
              />
            </div>
            </div>

            <button type="submit">Add Item</button>
          </form>
        )}
      </div>



      <EnhancedOrderSidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        sidebarType={sidebarType}
        orderDetails={orderDetails}
        handleOrderDetailsChange={handleOrderDetailsChange}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        items={items}
        removeFromOrder={removeFromOrder}
        createOrder={createOrder}
        formatPrice={formatPrice}
        companyId={companyId}
        onUpdateOrder={handleUpdateOrder}
      />

      {editingItem && (
        <div className="modal">
          <form onSubmit={handleEditSubmit}>
            <h2>Edit Item</h2>
            <input
              type="text"
              name="name"
              value={editingItem.name}
              onChange={handleEditChange}
              placeholder="Item name"
              required
            />
            <input
              type="text"
              name="sku"
              value={editingItem.sku}
              onChange={handleEditChange}
              placeholder="SKU"
              required
            />
            <input
              type="number"
              name="quantity"
              value={editingItem.quantity}
              onChange={handleEditChange}
              placeholder="Quantity"
              required
            />
            <input
              type="number"
              name="cost_price"
              value={editingItem.cost_price}
              onChange={handleEditChange}
              placeholder="Cost Price"
              step="0.01"
              required
            />
            <input
              type="number"
              name="sell_price"
              value={editingItem.sell_price}
              onChange={handleEditChange}
              placeholder="Sell Price"
              step="0.01"
              required
            />
            <input
              type="text"
              name="location"
              value={editingItem.location}
              onChange={handleEditChange}
              placeholder="Location"
              required
            />
            <input
              type="text"
              name="category"
              value={editingItem.category}
              onChange={handleEditChange}
              placeholder="Category"
              required
            />
            <button type="submit">Save Changes</button>
            <button type="button" onClick={() => setEditingItem(null)}>Cancel</button>
          </form>
        </div>
      )}

        {adjustingStock && (
                <div className="modal">
                  <form onSubmit={handleAdjustmentSubmit}>
                    <h2>Adjust Stock for {adjustingStock.name}</h2>
                    <p>Current Stock: {adjustingStock.quantity}</p>
                    <input
                      type="number"
                      value={adjustingStock.newQuantity}
                      onChange={handleAdjustmentChange}
                      placeholder="Enter new stock level"
                      required
                    />
                    <button type="submit">Confirm Adjustment</button>
                    <button type="button" onClick={() => setAdjustingStock(null)}>Cancel</button>
                  </form>
                </div>
      )}

    </div>
  );
}

export default Inventory;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useSidebar } from '../contexts/SidebarContext';
import { FaHome, FaBoxOpen, FaShoppingCart, FaTruck, FaAddressBook, FaUser, FaSignOutAlt, FaBars, FaChevronCircleLeft } from 'react-icons/fa';

function SidebarMenu() {
  const { currentUser } = useAuth();
  const { isExpanded, setIsExpanded } = useSidebar();
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (currentUser) {
      fetchUserDetails();
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentUser]);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const fetchUserDetails = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserName(userData.name || 'User');
        
        if (userData.companyId) {
          const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
          if (companyDoc.exists()) {
            setCompanyName(companyDoc.data().name || 'Company');
          }
        }
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const togglesidebarMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const menuItems = [
    { path: '/', icon: <FaHome />, text: 'Home' },
    { path: '/inventory', icon: <FaBoxOpen />, text: 'Inventory' },
    { path: '/sales-orders', icon: <FaShoppingCart />, text: 'Sales Orders' },
    { path: '/purchase-orders', icon: <FaTruck />, text: 'Purchase Orders' },
    { path: '/contacts', icon: <FaAddressBook />, text: 'Contacts' },
    { path: '/my-account', icon: <FaUser />, text: 'My Account' },
  ];

  return (
    <>
      {isMobile && (
        <button className="mobile-toggle" onClick={toggleSidebar}>
          <FaBars />
        </button>
      )}
      <div className={`sidebar-menu ${isExpanded ? 'expanded' : 'collapsed'} ${isMobile ? 'mobile' : ''}`}>
        <div className="sidebar-header">
          <img src="../ONEV_nobg.png" alt="Oneventory Logo" className="logo" />
          <button className="toggle-btn" onClick={toggleSidebar}>
            <FaChevronCircleLeft />
          </button>
        </div>
        {(isExpanded || isMobile) && (
          <div className="user-info">
            <p>{currentUser.firstName} {currentUser.lastName}</p>
            <p>{currentUser.role}</p>
            <p>{companyName}</p>
          </div>
        )}
        <nav className="sidebar-nav">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.path} className="nav-link" onClick={() => isMobile && setIsExpanded(false)}>
              {item.icon}
              {(isExpanded || isMobile) && <span>{item.text}</span>}
            </Link>
          ))}
        </nav>
        {currentUser && (
          <button onClick={handleSignOut} className="sign-out-button">
            <FaSignOutAlt />
            {(isExpanded || isMobile) && <span>Sign Out</span>}
          </button>
        )}
      </div>
    </>
  );
}


export default SidebarMenu;
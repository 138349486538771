import React, { useState, useEffect } from "react";
import { getXeroContacts } from "../utils/xeroAccounts";
import { db } from '../firebase';
import { collection, setDoc, doc , where,  getDoc} from "firebase/firestore";
import getCompanyIdForUser from '../utils/getCompanyIdForUser'; 
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from "firebase/auth";

function XeroContacts() {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const { currentUser } = useAuth();


// Helper function to get primary phone number
const getPrimaryPhone = (phones) => {
  if (!phones || !Array.isArray(phones) || phones.length === 0) return "";
  // First try to find DEFAULT phone
  const defaultPhone = phones.find(phone => phone.PhoneType === "DEFAULT");
  if (defaultPhone) return defaultPhone.PhoneNumber;
  
  // Then try to find MOBILE
  const mobilePhone = phones.find(phone => phone.PhoneType === "MOBILE");
  if (mobilePhone) return mobilePhone.PhoneNumber;
  
  // Finally, just take the first phone number
  return phones[0].PhoneNumber || "";
};

// Helper function to get primary address
const getPrimaryAddress = (addresses) => {
  if (!addresses || !Array.isArray(addresses) || addresses.length === 0) {
    return {
      street: "",
      city: "",
      region: "",
      postalCode: "",
      country: ""
    };
  }

  // First try to find STREET address
  const primaryAddress = addresses.find(addr => addr.AddressType === "STREET") || addresses[0];

  return {
    street: [
      primaryAddress.AddressLine1,
      primaryAddress.AddressLine2,
      primaryAddress.AddressLine3,
      primaryAddress.AddressLine4
    ].filter(Boolean).join(", "),
    city: primaryAddress.City || "",
    region: primaryAddress.Region || "",
    postalCode: primaryAddress.PostalCode || "",
    country: primaryAddress.Country || ""
  };
};

// Helper function to format address for Google Maps
const formatAddressForMaps = (address) => {
  const addressParts = [
    address.street,
    address.city,
    address.region,
    address.postalCode,
    address.country
  ].filter(Boolean);
  
  return encodeURIComponent(addressParts.join(", "));
};


  const syncContactsToFirestore = async (contactsData) => {
    try {
      // Get current user
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error("No authenticated user found");
    }
    
    // Get user's company information
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (!userDoc.exists()) {
      throw new Error("User profile not found");
    }
    
    // Assuming user has a default companyId in their profile
    const companyId = userDoc.data().companyId;
    if (!companyId) {
      throw new Error("Company ID not found in user profile");
    }

    const batch = [];
      
      for (const contact of contactsData) {
        const contactRef = doc(db, "contacts", contact.ContactID);
        const contactData = {
          contactId: contact.ContactID,
          name: contact.Name,
          firstName: contact.FirstName || "",
          lastName: contact.LastName || "",
          email: contact.EmailAddress || "",
          isSupplier: contact.IsSupplier || false,
          isCustomer: contact.IsCustomer || false,
          defaultCurrency: contact.DefaultCurrency || "",
          lastUpdated: new Date().toISOString(),
          companyId: companyId,
          createdBy: user.uid,
          createdAt: new Date().toISOString(),
          allPhones: contact.Phones || [],
          allAddresses: contact.Addresses || []
        };

       // console.log("Syncing contact:", contactData);
        
        batch.push(setDoc(contactRef, contactData, { merge: true }));
      }

      await Promise.all(batch);
      setSyncStatus("success");
    } catch (err) {
      console.error("Error syncing to Firestore:", err);
      setSyncStatus("error");
      throw err;
    }
  };

  const fetchAndSyncContacts = async () => {
    setSyncing(true);
    setSyncStatus(null);
    setError(null);
    
    try {
      const contactsData = await getXeroContacts();
      
      const xeroContacts = (contactsData.Contacts || []);

      setContacts(xeroContacts);
      
      await syncContactsToFirestore(xeroContacts);
      
    } catch (err) {
      console.error("Error in fetch and sync:", err);
      setError(err.message);
    } finally {
      setLoading(false);
      setSyncing(false);
    }
  };

  useEffect(() => {
    fetchAndSyncContacts();
  }, []);

  const handleRefresh = () => {
    fetchAndSyncContacts();
  };

  if (loading) return <div className="xero-container">Loading Xero contacts...</div>;
  
  return (
    <div>
      <div className="xero-header">
        <h1>Contacts</h1>
        
        <button 
          onClick={handleRefresh} 
          disabled={syncing}
          className="xero-button"
        >
          <div className="button-content">
            {syncing && <div className="loading-spinner" />}
            {syncing ? 'Syncing...' : 'Refresh Contacts'}
          </div>
        </button>
      </div>

      {error && (
        <div className="xero-error">{error}</div>
      )}

      {syncStatus === "success" && (
        <div className="xero-success">Contacts successfully synchronised with Firestore</div>
      )}

      <table className="xero-table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Supplier or Customer?</th>
            <th>Currency</th>
          </tr>
        </thead>
        <tbody>
        
        {contacts
         .filter(contact => contact.companyId == companyId) 
          .map((contact) => {
            const primaryPhone = getPrimaryPhone(contact.Phones);
            const primaryAddress = getPrimaryAddress(contact.Addresses);
            const mapsUrl = `https://www.google.com/maps/place/${formatAddressForMaps(primaryAddress)}`;
            
            return (
              <tr key={contact.ContactID}>
                <td>{contact.Name}</td>
                <td>{contact.FirstName} {contact.LastName}</td>
                <td>{contact.EmailAddress}</td>
                <td>
                  {primaryPhone && (
                    <a href={`tel:${primaryPhone}`} className="contact-link">
                      {primaryPhone}
                    </a>
                  )}
                </td>
                <td>
                  {primaryAddress.street && (
                    <a href={mapsUrl} target="_blank" rel="noopener noreferrer" className="contact-link">
                      {primaryAddress.street}<br />
                      {[
                        primaryAddress.city,
                        primaryAddress.region,
                        primaryAddress.postalCode,
                        primaryAddress.country
                      ].filter(Boolean).join(", ")}
                    </a>
                  )}
                </td>
                <td>
                  {[
                    contact.IsSupplier ? "Supplier" : "",
                    contact.IsCustomer ? "Customer" : ""
                  ].filter(Boolean).join(", ")}
                </td>
                <td>{contact.DefaultCurrency}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>These contacts are sourced from your Xero tenancy.</p>
    </div>
  );
}

export default XeroContacts;
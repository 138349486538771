import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, getDocs, updateDoc, collection, addDoc, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { generatePDF } from './OrderPDF';
import { createXeroSalesOrder } from '../utils/xeroAccounts';
import { Plus, Printer, Send, Copy, Save } from 'lucide-react';

const OrderDetails = ({ type }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({
    id: '',
    quantity: 1
    });
    const [editingItemId, setEditingItemId] = useState(null);
  

    useEffect(() => {
        fetchOrderDetails();
      }, [orderId]);
      
      useEffect(() => {
        if (order) {
          fetchComments();
          fetchAvailableProducts();
        }
      }, [order]);

  const fetchOrderDetails = async () => {
    try {
      const orderDoc = await getDoc(doc(db, `${type}Orders`, orderId));
      if (orderDoc.exists()) {
        setOrder({ id: orderDoc.id, ...orderDoc.data() });
      } else {
        setError('Order not found');
      }
    } catch (err) {
      setError('Error fetching order details');
    } finally {
      setLoading(false);
    }
  };

  const calculateDaysDifference = (date1, date2) => {
    try {
      if (!date1 || !date2) return "-";
      
      // Handle ISO string dates or timestamps
      const d1 = date1 instanceof Date ? date1 : new Date(date1);
      const d2 = date2 instanceof Date ? date2 : new Date(date2);
  
      // Check if dates are valid
      if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
        console.log('Invalid date detected:', { date1, date2 });
        return "-";
      }
  
      const diffTime = Math.abs(d2.getTime() - d1.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      return diffDays.toString(); // Convert to string to avoid NaN warning
    } catch (error) {
      console.error('Error calculating days difference:', error);
      return "-";
    }
  };

  const fetchAvailableProducts = async () => {
    if (!order?.companyId) {
      console.log('No companyId available yet');
      return;
    }
  
    try {
      const productsQuery = query(
        collection(db, 'inventory'),
        where('companyId', '==', order.companyId)
      );
      
      const querySnapshot = await getDocs(productsQuery);
      const products = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Filter out products that are already in the order
      const existingProductIds = order?.items.map(item => item.id) || [];
      const availableProducts = products.filter(product => 
        !existingProductIds.includes(product.id)
      );
      
      setAvailableProducts(availableProducts);
    } catch (error) {
      console.error('Error fetching available products:', error);
    }
  };
  
  // Add function to handle adding new product
  const handleAddProduct = async () => {
    if (!newProduct.id || newProduct.quantity < 1) return;
  
    const productToAdd = availableProducts.find(p => p.id === newProduct.id);
    if (!productToAdd) return;
  
    try {
      const newItem = {
        id: productToAdd.id,
        name: productToAdd.name,
        quantity: newProduct.quantity,
        price: type === 'sales' ? productToAdd.sell_price : productToAdd.cost_price,
        status: 'not_processed'
      };
  
      const updatedItems = [...order.items, newItem];
      const updatedTotal = updatedItems.reduce((total, item) => 
        total + (item.quantity * item.price), 0
      );
  
      await updateDoc(doc(db, `${type}Orders`, orderId), {
        items: updatedItems,
        totalAmount: updatedTotal
      });
  
      // Update local state
      setOrder(prev => ({
        ...prev,
        items: updatedItems,
        totalAmount: updatedTotal
      }));
  
      // Reset form and close modal
      setNewProduct({ id: '', quantity: 1 });
      setShowAddProduct(false);
  
      // Refresh available products
      fetchAvailableProducts();
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  
  // Add this JSX for the product addition modal
  {showAddProduct && (
    <div className="modal">
      <div className="modal-content">
        <h3>Add Product</h3>
        <div className="form-group">
          <select
            value={newProduct.id}
            onChange={(e) => setNewProduct(prev => ({
              ...prev,
              id: e.target.value
            }))}
          >
            <option value="">Select Product</option>
            {availableProducts.map(product => (
              <option key={product.id} value={product.id}>
                {product.name} - Stock: {product.quantity}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Quantity:</label>
          <input
            type="number"
            min="1"
            value={newProduct.quantity}
            onChange={(e) => setNewProduct(prev => ({
              ...prev,
              quantity: parseInt(e.target.value) || 1
            }))}
          />
        </div>
        <div className="modal-actions">
          <button onClick={handleAddProduct}>Add</button>
          <button onClick={() => setShowAddProduct(false)}>Cancel</button>
        </div>
      </div>
    </div>
  )}

  const fetchComments = async () => {
    try {
      const commentsRef = collection(db, `${type}Orders/${orderId}/comments`);
      const commentsSnapshot = await getDocs(commentsRef);
      const commentsList = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsList.sort((a, b) => b.timestamp - a.timestamp));
    } catch (err) {
      console.error('Error fetching comments:', err);
    }
  };

  const addComment = async () => {
    if (!newComment.trim()) return;

    try {
      const commentsRef = collection(db, `${type}Orders/${orderId}/comments`);
      await addDoc(commentsRef, {
        text: newComment,
        userId: currentUser.uid,
        userName: currentUser.displayName || currentUser.email,
        timestamp: Timestamp.now()
      });
      setNewComment('');
      fetchComments();
    } catch (err) {
      console.error('Error adding comment:', err);
    }
  };

  const updateItemStatus = async (itemId, status) => {
    const updatedItems = order.items.map(item => 
      item.id === itemId ? { ...item, status } : item
    );

    try {
      await updateDoc(doc(db, `${type}Orders`, orderId), { items: updatedItems });
      setOrder(prev => ({ ...prev, items: updatedItems }));
    } catch (err) {
      console.error('Error updating item status:', err);
    }
  };

  const updateOrderStatus = async (status) => {
    try {
      await updateDoc(doc(db, `${type}Orders`, orderId), { status });
      setOrder(prev => ({ ...prev, status }));
    } catch (err) {
      console.error('Error updating order status:', err);
    }
  };

  const copyToDraft = async () => {
    try {
      const { id, status, createdAt, ...orderData } = order;
      const newOrder = {
        ...orderData,
        status: 'draft',
        createdAt: new Date().toISOString(),
        orderId: `${orderData.orderId}-copy`,
        items: orderData.items.map(item => ({ ...item, status: 'not_processed' }))
      };

      const docRef = await addDoc(collection(db, `${type}Orders`), newOrder);
      navigate(`/${type}-orders/${docRef.id}`);
    } catch (err) {
      console.error('Error copying order:', err);
    }
  };

  const handlePrintPDF = async () => {
    try {
      const blob = await generatePDF(order, type);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${type}-order-${order.orderId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const removeItem = async (itemId) => {
    try {
      // Filter out the item to be removed
      const updatedItems = order.items.filter(item => item.id !== itemId);
      
      // Recalculate total amount
      const updatedTotal = updatedItems.reduce((total, item) => 
        total + (item.quantity * item.price), 0
      );
  
      // Update in Firestore
      await updateDoc(doc(db, `${type}Orders`, orderId), {
        items: updatedItems,
        totalAmount: updatedTotal
      });
  
      // Update local state
      setOrder(prev => ({
        ...prev,
        items: updatedItems,
        totalAmount: updatedTotal
      }));
  
      // Refresh available products since we removed one
      fetchAvailableProducts();
  
    } catch (error) {
      console.error('Error removing item:', error);
      alert('Failed to remove item. Please try again.');
    }
  };

  const handleSendToXero = async () => {
    try {
      const xeroResponse = await createXeroSalesOrder(order);
      await updateDoc(doc(db, `${type}Orders`, orderId), {
        xeroSent: true,
        xeroInvoiceId: xeroResponse.Invoices[0].InvoiceID
      });
      alert('Successfully sent to Xero');
    } catch (error) {
      console.error('Error sending to Xero:', error);
      alert('Failed to send to Xero');
    }
  };

  const saveItemChanges = async (itemId) => {
    try {
      const updatedTotal = order.items.reduce((total, item) => 
        total + (item.quantity * item.price), 0
      );
  
      await updateDoc(doc(db, `${type}Orders`, orderId), {
        items: order.items,
        totalAmount: updatedTotal
      });
  
      setOrder(prev => ({
        ...prev,
        totalAmount: updatedTotal
      }));
      
      setEditingItemId(null);
    } catch (error) {
      console.error('Error saving item changes:', error);
      alert('Failed to save changes. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
    
        <h1>{type === 'sales' ? 'Sales' : 'Purchase'} Order: {order.orderId}</h1>

      <div className="filter-container">
        
        <div className="action-buttons">
          <button onClick={handlePrintPDF}><Printer size={16} /> Print PDF</button>
          <button onClick={handleSendToXero}><Send size={16} /> Send to Xero</button>
          <button onClick={copyToDraft}><Copy size={16} /> Copy to Draft</button>
        </div>
      </div>

      <div className="order-info">
        <div className="info-group">
          <h3>{type === 'sales' ? 'Customer' : 'Supplier'}</h3>
          <p>{type === 'sales' ? order.customerName : order.supplierName}</p>
        </div>

        <div className="info-group">
    <h3>Order Timing</h3>
    <div className="timing-grid">
      <div>
        <label>Date Placed:</label>
        <p>{new Date(order.createdAt).toLocaleDateString()}</p>
      </div>
      <div>
        <label>Due Date:</label>
        <p>{new Date(order.dueDate).toLocaleDateString()}</p>
      </div>
      <div>
        <label>Days Since Placed:</label>
        <p>{calculateDaysDifference(order.createdAt, new Date())}</p>
      </div>
      <div>
        <label>Days Until Due:</label>
        <p>{calculateDaysDifference(new Date(), order.dueDate)}</p>
      </div>
    </div>
  </div>

  <div className="info-group">
    <h3>Status</h3>
    <select 
      value={order.status} 
      onChange={(e) => updateOrderStatus(e.target.value)}
    >
      <option value="draft">Draft</option>
      <option value="pending">Pending</option>
      <option value="processing">Processing</option>
      <option value="completed">Completed</option>
      <option value="cancelled">Cancelled</option>
    </select>
  </div>

        <div className="info-group">
          <h3>Dates</h3>
          <p>Created: {new Date(order.createdAt).toLocaleDateString()}</p>
          <p>Due: {new Date(order.dueDate).toLocaleDateString()}</p>
        </div>
      </div>

      <div className="items-section">
        <h2>Items</h2>
        <button onClick={() => setShowAddProduct(true)}><Plus size={16} /> Add Product</button>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {order.items.map(item => (
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                {editingItemId === item.id ? (
                    <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) => {
                        const updatedItems = order.items.map(i => 
                        i.id === item.id ? { ...i, quantity: parseInt(e.target.value) || 0 } : i
                        );
                        setOrder(prev => ({ ...prev, items: updatedItems }));
                    }}
                    className="edit-input"
                    />
                ) : (
                    item.quantity
                )}
                </td>
                <td>
                {editingItemId === item.id ? (
                    <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={item.price}
                    onChange={(e) => {
                        const updatedItems = order.items.map(i => 
                        i.id === item.id ? { ...i, price: parseFloat(e.target.value) || 0 } : i
                        );
                        setOrder(prev => ({ ...prev, items: updatedItems }));
                    }}
                    className="edit-input"
                    />
                ) : (
                    `$${item.price.toFixed(2)}`
                )}
                </td>
                <td>${(item.quantity * item.price).toFixed(2)}</td>
                <td>
                <select
                    value={item.status || 'not_processed'}
                    onChange={(e) => updateItemStatus(item.id, e.target.value)}
                >
                    <option value="not_processed">Not Processed</option>
                    <option value="in_stock">In Stock</option>
                    <option value="ordered">Ordered</option>
                    <option value="shipped">Shipped</option>
                </select>
                </td>
                <td>
                {editingItemId === item.id ? (
                    <div className="action-buttons">
                    <button onClick={() => saveItemChanges(item.id)}>Save</button>
                    <button onClick={() => setEditingItemId(null)}>Cancel</button>
                    </div>
                ) : (
                    <div className="action-buttons">
                    <button onClick={() => setEditingItemId(item.id)}>Edit</button>
                    <button onClick={() => removeItem(item.id)}>Remove</button>
                    </div>
                )}
                </td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="comments-section">
        <h2>Comments</h2>
        <div className="comments-list">
          {comments.map(comment => (
            <div key={comment.id} className="comment">
              <div className="comment-header">
                <span className="comment-user">{comment.userName}</span>
                <span className="comment-date">
                  {comment.timestamp.toDate().toLocaleString()}
                </span>
              </div>
              <p className="comment-text">{comment.text}</p>
            </div>
          ))}
        </div>
        <div className="add-comment">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
          />
          <button onClick={addComment}>Add Comment</button>
        </div>
      </div>

      {showAddProduct && (
      <div className="modal">
        <div className="modal-content">
          <h3>Add Product</h3>
          <div className="form-group">
            <select
              value={newProduct.id}
              onChange={(e) => setNewProduct(prev => ({
                ...prev,
                id: e.target.value
              }))}
            >
              <option value="">Select Product</option>
              {availableProducts.map(product => (
                <option key={product.id} value={product.id}>
                  {product.name} - Stock: {product.quantity}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Quantity:</label>
            <input
              type="number"
              min="1"
              value={newProduct.quantity}
              onChange={(e) => setNewProduct(prev => ({
                ...prev,
                quantity: parseInt(e.target.value) || 1
              }))}
            />
          </div>
          <div className="modal-actions">
            <button onClick={handleAddProduct}>Add</button>
            <button onClick={() => setShowAddProduct(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}

    </div>
  );
};

export default OrderDetails;
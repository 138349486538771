import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";
import { getAuthUrl } from "../utils/xeroAuth";
import { useAuth } from "../contexts/AuthContext";
import getCompanyIdForUser from "../utils/getCompanyIdForUser";
import XeroConnect from "./XeroConnect";
import ReactApexChart from "react-apexcharts";
import SalesPurchasesChart from "./SalesPurchasesChart";
import { StockLevelDonut, LowStockBar, ProfitTrendLine, CategoryPerformance } from '../components/HomeCharts';
import { useChartData } from '../hooks/useChartData';

function XeroAuthButton() {
  const handleAuth = () => {
    getAuthUrl().then((url) => {
      console.log("Redirecting to ", url);
      window.location.href = url;
    });
  };

  return <button onClick={handleAuth}>Connect to Xero</button>;
}

function Home() {
  // States
  const [stockValueCost, setStockValueCost] = useState(0);
  const [stockValueRRP, setStockValueRRP] = useState(0);
  const [averageMargin, setAverageMargin] = useState(0);
  const [items, setItems] = useState([]);
  const [itemsInStock, setItemsInStock] = useState(0);
  const [outstandingSalesOrders, setOutstandingSalesOrders] = useState(0);
  const [outstandingPurchaseOrders, setOutstandingPurchaseOrders] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const { currentUser } = useAuth();
  const [totalStockQuantity, setTotalStockQuantity] = useState(0);
  const [lowStockItems, setLowStockItems] = useState([]);
  const [dateRange, setDateRange] = useState('7');
  const [salesData, setSalesData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const [topSuppliers, setTopSuppliers] = useState([]);
  const chartData = useChartData(companyId);

  // Helper Functions
  const getDateRange = (range) => {
    const today = new Date();
    let startDate = new Date();
    
    switch(range) {
      case '7':
        startDate.setDate(today.getDate() - 7);
        break;
      case '30':
        startDate.setDate(today.getDate() - 30);
        break;
      case '90':
        startDate.setDate(today.getDate() - 90);
        break;
      case 'FY':
        const currentYear = today.getFullYear();
        const julyFirst = new Date(currentYear, 6, 1);
        startDate = today < julyFirst ? 
          new Date(currentYear - 1, 6, 1) : 
          julyFirst;
        break;
      default:
        startDate.setDate(today.getDate() - 7);
    }
    
    return {
      startDate,
      endDate: today
    };
  };

  // Add this function to fetch and process top customers and suppliers
const fetchTopTransactions = async () => {
  try {
    const { startDate, endDate } = getDateRange(dateRange);
    
    // Fetch sales orders for customers
    const salesQuery = query(
      collection(db, "salesOrders"),
      where("companyId", "==", companyId),
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate)
    );

    console.log(salesQuery);

    // Fetch purchase orders for suppliers
    const purchaseQuery = query(
      collection(db, "purchaseOrders"),
      where("companyId", "==", companyId),
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate)
    );

    console.log(purchaseQuery);

    const [salesSnapshot, purchaseSnapshot] = await Promise.all([
      getDocs(salesQuery),
      getDocs(purchaseQuery)
    ]);

    // Process customer data
    const customerTotals = {};
    salesSnapshot.forEach(doc => {
      const data = doc.data();
      const customerName = data.customerName;
      const amount = data.totalAmount || 0;
      
      customerTotals[customerName] = (customerTotals[customerName] || 0) + amount;
    });

    // Process supplier data
    const supplierTotals = {};
    purchaseSnapshot.forEach(doc => {
      const data = doc.data();
      const supplierName = data.supplierName;
      const amount = data.totalAmount || 0;
      
      supplierTotals[supplierName] = (supplierTotals[supplierName] || 0) + amount;
    });

    // Sort and get top 5 customers
    const sortedCustomers = Object.entries(customerTotals)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([name, total]) => ({
        name,
        total,
        percentage: (total / Object.values(customerTotals).reduce((a, b) => a + b, 0) * 100).toFixed(1)
      }));

    // Sort and get top 5 suppliers
    const sortedSuppliers = Object.entries(supplierTotals)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([name, total]) => ({
        name,
        total,
        percentage: (total / Object.values(supplierTotals).reduce((a, b) => a + b, 0) * 100).toFixed(1)
      }));

    setTopCustomers(sortedCustomers);
    setTopSuppliers(sortedSuppliers);

  } catch (error) {
    console.error("Error fetching top transactions:", error);
  }
};

  const calculateMarginPercentage = (cost, sell) => {
    if (cost === 0) return 0;
    return ((sell - cost) / cost) * 100;
  };

  // Data Fetching Functions
  const fetchCompanyId = async () => {
    const id = await getCompanyIdForUser(currentUser.uid);
    if (id) {
      setCompanyId(id);
    } else {
      setError("Failed to fetch company ID. Please try again.");
    }
  };

  const fetchInventoryData = async () => {
    try {
      const inventoryRef = collection(db, "inventory");
      const q = query(inventoryRef, where("companyId", "==", companyId));
      const querySnapshot = await getDocs(q);
      
      let totalCostValue = 0;
      let totalRRPValue = 0;
      let totalQuantity = 0;
      let itemCount = 0;
      let totalMargin = 0;
      let lowStockList = [];
      const fetchedItems = [];

      querySnapshot.forEach((doc) => {
        const item = {
          id: doc.id,
          ...doc.data()
        };
        
        const itemCostValue = item.quantity * item.cost_price;
        const itemRRPValue = item.quantity * item.sell_price;
        const itemMargin = calculateMarginPercentage(item.cost_price, item.sell_price);
        
        totalCostValue += itemCostValue;
        totalRRPValue += itemRRPValue;
        totalQuantity += item.quantity;
        totalMargin += itemMargin;
        itemCount++;

        if (item.quantity <= item.reorder_point) {
          lowStockList.push({
            name: item.name,
            currentStock: item.quantity,
            reorderPoint: item.reorder_point
          });
        }

        fetchedItems.push({
          ...item,
          costValue: itemCostValue,
          rrpValue: itemRRPValue,
          margin: itemMargin
        });
      });

      const avgMargin = itemCount > 0 ? (totalMargin / itemCount) : 0;

      setStockValueCost(totalCostValue);
      setStockValueRRP(totalRRPValue);
      setAverageMargin(avgMargin);
      setTotalStockQuantity(totalQuantity);
      setItemsInStock(itemCount);
      setLowStockItems(lowStockList);
      setItems(fetchedItems);

    } catch (err) {
      console.error("Error fetching inventory data: ", err);
      setError("Failed to fetch inventory data. Please try again.");
    }
  };

  const fetchOrderData = async () => {
    try {
      const salesOrdersQuery = query(
        collection(db, "salesOrders"),
        where("status", "==", "pending"),
        where("companyId", "==", companyId)
      );
      const salesOrdersSnapshot = await getDocs(salesOrdersQuery);
      setOutstandingSalesOrders(salesOrdersSnapshot.size);

      const purchaseOrdersQuery = query(
        collection(db, "purchaseOrders"),
        where("status", "==", "pending"),
        where("companyId", "==", companyId)
      );
      const purchaseOrdersSnapshot = await getDocs(purchaseOrdersQuery);
      setOutstandingPurchaseOrders(purchaseOrdersSnapshot.size);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching order data: ", err);
      setError("Failed to fetch order data. Please try again.");
    }
  };

  const fetchHistoricalData = async () => {
    try {
      const { startDate, endDate } = getDateRange(dateRange);
      
      // Convert to ISO strings for comparison
      const startISOString = startDate.toISOString();
      const endISOString = endDate.toISOString();
  
      const salesQuery = query(
        collection(db, "salesOrders"),
        where("companyId", "==", companyId),
        where("createdAt", ">=", startISOString),
        where("createdAt", "<=", endISOString),
        orderBy("createdAt")
      );
      
      const purchaseQuery = query(
        collection(db, "purchaseOrders"),
        where("companyId", "==", companyId),
        where("createdAt", ">=", startISOString),
        where("createdAt", "<=", endISOString),
        orderBy("createdAt")
      );
  
      const [salesSnapshot, purchaseSnapshot] = await Promise.all([
        getDocs(salesQuery),
        getDocs(purchaseQuery)
      ]);
  
      const salesByDate = {};
      const salesByLocation = {};
  
      // Process sales data
      salesSnapshot.forEach(doc => {
        const data = doc.data();
        // Parse the ISO string date
        const date = new Date(data.createdAt);
        const dateStr = date.toISOString().split('T')[0]; // Get just the date part
        salesByDate[dateStr] = (salesByDate[dateStr] || 0) + (data.totalAmount || 0);
        
        if (data.customerLocation) {
          salesByLocation[data.customerLocation] = 
            (salesByLocation[data.customerLocation] || 0) + (data.totalAmount || 0);
        }
      });
  
      // Process purchase data
      const purchaseByDate = {};
      purchaseSnapshot.forEach(doc => {
        const data = doc.data();
        const date = new Date(data.createdAt);
        const dateStr = date.toISOString().split('T')[0];
        purchaseByDate[dateStr] = (purchaseByDate[dateStr] || 0) + (data.totalAmount || 0);
      });
  
      // Fill in missing dates
      const allDates = getDatesInRange(startDate, endDate);
      const formattedSales = [];
      const formattedPurchases = [];
  
      allDates.forEach(date => {
        const dateStr = date.toISOString().split('T')[0];
        formattedSales.push({
          x: new Date(dateStr).getTime(), // Convert to timestamp for ApexCharts
          y: salesByDate[dateStr] || 0
        });
        formattedPurchases.push({
          x: new Date(dateStr).getTime(), // Convert to timestamp for ApexCharts
          y: purchaseByDate[dateStr] || 0
        });
      });
  
      setSalesData(formattedSales);
      setPurchaseData(formattedPurchases);
      setLocationData(Object.entries(salesByLocation).map(([location, value]) => ({
        x: location,
        y: value
      })));
  
    } catch (error) {
      console.error("Error fetching historical data:", error);
      setError("Failed to fetch historical data");
    }
  };

// Add helper function to get all dates in range
const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);
  
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  
  return dates;
};

  // Effects
  useEffect(() => {
    if (currentUser) {
      fetchCompanyId();
    }
  }, [currentUser]);

  useEffect(() => {
    if (companyId) {
      fetchInventoryData();
      fetchOrderData();
      fetchHistoricalData();
      fetchTopTransactions();
    }
  }, [companyId, dateRange]);

  // Update chart options
const lineChartOptions = {
  chart: {
    type: 'line',
    height: 350,
    zoom: {
      enabled: true
    },
    toolbar: {
      show: true
    }
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeFormatter: {
        year: 'yyyy',
        month: 'MMM yyyy',
        day: 'dd MMM',
        hour: 'HH:mm'
      }
    }
  },
  yaxis: {
    labels: {
      formatter: (value) => `$${value.toFixed(2)}`
    }
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    },
    y: {
      formatter: (value) => `$${value.toFixed(2)}`
    }
  },
  colors: ['#2E93fA', '#66DA26'],
  legend: {
    show: true,
    position: 'top'
  }
};

  const mapChartOptions = {
    chart: {
      type: 'treemap',
      height: 350
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    }
  };

  

  // Update the chart rendering
const renderCharts = () => (
  <div className="charts-container">
    <div className="chart-controls">
      <select 
        value={dateRange} 
        onChange={(e) => setDateRange(e.target.value)}
        className="date-range-selector"
      >
        <option value="7">Last 7 Days</option>
        <option value="30">Last 30 Days</option>
        <option value="90">Last 90 Days</option>
        <option value="FY">Current Financial Year</option>
      </select>
    </div>

    <div className="chart-grid">
      <div className="chart-card">
        <h3>Sales vs Purchases</h3>
        <SalesPurchasesChart 
          salesData={salesData}
          purchaseData={purchaseData}
        />
      </div>

      {/* Sales by Location Chart 
      <div className="chart-card">
        <h3>Sales by Location</h3>
        {locationData.length > 0 ? (
          <ReactApexChart
            options={mapChartOptions}
            series={[{
              data: locationData
            }]}
            type="treemap"
            height={350}
          />
        ) : (
          <div className="no-data">No location data available</div>
        )}
      </div>
      */}

      <StockLevelDonut stockLevels={chartData.stockLevels} />
      <LowStockBar lowStockItems={chartData.lowStockItems} />
      <ProfitTrendLine profitData={chartData.profitData} />
      <CategoryPerformance categoryData={chartData.categoryData} />

    </div>
  </div>
);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Calculate potential profit
  const potentialProfit = stockValueRRP - stockValueCost;

  return (
    <div>
      <h1>Welcome to Oneventory</h1>
      <XeroConnect />
      
      <div className="dashboard-grid">
        <div className="dashboard-item">
          <h2>Cost Price Value</h2>
          <p>${stockValueCost.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</p>
        </div>
        <div className="dashboard-item">
          <h2>Sell Price Value</h2>
          <p>${stockValueRRP.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</p>
        </div>
        <div className="dashboard-item special">
          <h2>Potential Profit</h2>
          <p>${potentialProfit.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</p>
          <span className="margin-indicator">
            Average Margin: {averageMargin.toFixed(1)}%
          </span>
        </div>
        <div className="dashboard-item">
          <h2>Total Stock Quantity</h2>
          <p>{totalStockQuantity.toLocaleString()}</p>
        </div>
        <div className="dashboard-item">
          <h2>Unique Items</h2>
          <p>{itemsInStock.toLocaleString()}</p>
        </div>
        <div className="dashboard-item">
          <Link to="/sales-orders">
            <h2>Outstanding Sales Orders</h2>
            <p>{outstandingSalesOrders}</p>
          </Link>
        </div>
        <div className="dashboard-item">
          <Link to="/purchase-orders">
            <h2>Outstanding Purchase Orders</h2>
            <p>{outstandingPurchaseOrders}</p>
          </Link>
        </div>
      </div>

      {lowStockItems.length > 0 && (
        <div className="low-stock-alert">
          <h2>Low Stock Alert</h2>
          <div className="low-stock-items">
            {lowStockItems.map((item, index) => (
              <div key={index} className="low-stock-item">
                <span className="item-name">{item.name}</span>
                <span className="stock-level">Stock: {item.currentStock}</span>
                <span className="reorder-point">Reorder at: {item.reorderPoint}</span>
              </div>
            ))}
          </div>
        </div>
      )}

    {renderCharts()}
    

    </div>
  );
}

export default Home;
const API_URL = "https://us-central1-onev-8da8c.cloudfunctions.net/api";
// const API_URL = "http://localhost:5001/onev-8da8c/us-central1/api"; // TODO: change this

export const getAuthUrl = async () => {
  try {
    console.log("Fetching Xero auth URL from:", `${API_URL}/xero-auth-url`);
    const response = await fetch(`${API_URL}/xero-auth-url`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    });

    console.log("response ->", response);

    console.log("Response status:", response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response:", errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Received data:", data);
    return data.url;
  } catch (error) {
    console.error("Error getting auth URL:", error);
    throw error;
  }
};

export const exchangeCodeForToken = async (code) => {
  const response = await fetch(`${API_URL}/xero-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ code }),
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to exchange code for token");
  }

  return response.json();
};

export const refreshAccessToken = async (refreshToken) => {
  const response = await fetch(`${API_URL}/xero-token-refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({ refreshToken }),
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to exchange code for token");
  }

  return response.json();
};

export const getXeroTenants = async (accessToken) => {
  const response = await fetch(`${API_URL}/xero-tenants`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    mode: "cors",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch Xero tenants");
  }

  return response.json();
};

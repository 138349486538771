import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../hooks/subscription';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const { isLoading, isActive, isTrialing, error } = useSubscriptionStatus();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-24 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="text-red-500">
          Error: {error}. Please try refreshing the page.
        </div>
      </div>
    );
  }

  // Redirect to subscription page if not active or trialing
  if (!isActive && !isTrialing) {
    return <Navigate to="/subscription" />;
  }

  return children;
};

export default ProtectedRoute;
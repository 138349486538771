import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

function ValidateUsers() {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      fetchPendingUsers();
    }
  }, [currentUser]);

  const fetchPendingUsers = async () => {
    setLoading(true);
    setError('');
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userData = userDoc.data();
      const companyId = userData.companyId;

      if (!companyId) {
        throw new Error('User is not associated with a company');
      }

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('companyId', '==', companyId), where('status', '==', 'pending'));
      const querySnapshot = await getDocs(q);
      
      const users = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPendingUsers(users);
    } catch (error) {
      setError('Failed to fetch pending users: ' + error.message);
      console.error('Error fetching pending users:', error);
    }
    setLoading(false);
  };

  const handleValidate = async (userId, approve) => {
    try {
      await updateDoc(doc(db, 'users', userId), {
        status: approve ? 'active' : 'rejected'
      });
      fetchPendingUsers(); // Refresh the list
    } catch (error) {
      setError('Failed to update user status: ' + error.message);
      console.error('Error updating user status:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="validate-users-container">
      <h2>Pending Users</h2>
      {pendingUsers.length === 0 ? (
        <p>No pending users</p>
      ) : (
        <ul>
          {pendingUsers.map(user => (
            <li key={user.id}>
              {user.firstName} {user.lastName} ({user.email})
              <button onClick={() => handleValidate(user.id, true)}>Approve</button>
              <button onClick={() => handleValidate(user.id, false)}>Reject</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ValidateUsers;
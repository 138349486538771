import React, { useState, useEffect } from "react";
import { getAuthUrl, getXeroTenants } from "../utils/xeroAuth";
import { useAuth } from "../contexts/AuthContext";

function XeroConnect() {
  const [isConnected, setIsConnected] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    checkXeroConnection();
  }, [currentUser]);

  const checkXeroConnection = async () => {
    const token = localStorage.getItem("xeroToken");
    if (token) {
      try {
        const defaultTenantString = localStorage.getItem("xeroDefaultTenant");
        if (!defaultTenantString) {
          setIsConnected(false);
          return
        }
        const defaultTenant = JSON.parse(defaultTenantString);
        setIsConnected(true);
        setCompanyName(defaultTenant.tenantName);
      } catch (error) {
        console.error("Error checking Xero connection:", error);
        // If there's an error, assume the token is invalid and clear it
        localStorage.removeItem("xeroToken");
      }
    }
  };

  const handleConnectXero = async () => {
    try {
      const url = await getAuthUrl();
      window.location.href = url;
    } catch (error) {
      console.error("Error connecting to Xero:", error);
      setError("Failed to connect to Xero. Please try again later.");
    }
  };

  return (
    <button onClick={handleConnectXero} className="xero-connect-button">
      {isConnected ? `Connected: ${companyName}` : "Connect to Xero"}
    </button>
  );
}

export default XeroConnect;

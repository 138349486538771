import { db } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  orderBy, 
  onSnapshot,
  Timestamp,
  updateDoc,
  limit,
  doc,
  getDocs
} from 'firebase/firestore';

export const NOTIFICATION_TYPES = {
  SALES_ORDER: 'SALES_ORDER',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  INVENTORY: 'INVENTORY',
  USER: 'USER',
  SYSTEM: 'SYSTEM'
};

// Collection references
const salesOrdersRef = collection(db, 'salesOrders');
const purchaseOrdersRef = collection(db, 'purchaseOrders');
const inventoryRef = collection(db, 'inventory');
const notificationsRef = collection(db, 'notifications');

const createNotification = async ({ 
  userId, 
  companyId, 
  type, 
  title, 
  message, 
  referenceId, 
  referenceType 
}) => {
  try {
    await addDoc(notificationsRef, {
      userId,
      companyId,
      type,
      title,
      message,
      referenceId,
      referenceType,
      createdAt: Timestamp.now(),
      read: false
    });
  } catch (error) {
    console.error('Error creating notification:', error);
  }
};


const getNotifications = async (userId, companyId, options = {}) => {
  try {
    const { unreadOnly = false, limit: queryLimit = 50 } = options;
    
    let notificationQuery = query(
      collection(db, 'notifications'),
      where('companyId', '==', companyId),
      orderBy('createdAt', 'desc'),
      limit(queryLimit)
    );

    if (unreadOnly) {
      notificationQuery = query(
        notificationQuery,
        where('read', '==', false)
      );
    }

    const querySnapshot = await getDocs(notificationQuery);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate()
    }));
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }
};

const markAsRead = async (notificationId) => {
  try {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, {
      read: true
    });
  } catch (error) {
    console.error('Error marking notification as read:', error);
  }
};

const markAllAsRead = async (userId, companyId) => {
  try {
    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('companyId', '==', companyId),
      where('read', '==', false)
    );

    const querySnapshot = await getDocs(notificationsQuery);
    const updatePromises = querySnapshot.docs.map(doc => 
      updateDoc(doc.ref, { read: true })
    );

    await Promise.all(updatePromises);
  } catch (error) {
    console.error('Error marking all notifications as read:', error);
  }
};

const getNotificationMessage = (type, data) => {
  switch (type) {
    case NOTIFICATION_TYPES.SALES_ORDER:
      return `New sales order #${data.orderId} created for ${data.customerName}`;
    case NOTIFICATION_TYPES.PURCHASE_ORDER:
      return `New purchase order #${data.orderId} created for ${data.supplierName}`;
    case NOTIFICATION_TYPES.INVENTORY:
      if (data.action === 'low_stock') {
        return `Low stock alert: ${data.itemName} has fallen below reorder point`;
      }
      return `Inventory updated for ${data.itemName}`;
    default:
      return data.message || 'New notification';
  }
};

// Snapshot listeners setup
export const setupNotificationListeners = (companyId, userId) => {
  const listeners = [];

  // Sales Orders Listener
  const salesOrdersQuery = query(
    salesOrdersRef,
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc')
  );

  const salesOrdersUnsubscribe = onSnapshot(salesOrdersQuery, (snapshot) => {
    snapshot.docChanges().forEach(async (change) => {
      const orderData = { id: change.doc.id, ...change.doc.data() };

      if (change.type === 'added') {
        await createNotification({
          userId,
          companyId,
          type: NOTIFICATION_TYPES.SALES_ORDER,
          title: 'New Sales Order Created',
          message: `Sales order #${orderData.orderId} created for ${orderData.customerName}`,
          referenceId: orderData.id,
          referenceType: 'SALES_ORDER'
        });
      } else if (change.type === 'modified') {
        const oldData = change.doc.data();
        
        // Check if status changed
        if (oldData.status !== orderData.status) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.SALES_ORDER,
            title: 'Sales Order Status Updated',
            message: `Sales order #${orderData.orderId} status changed to ${orderData.status}`,
            referenceId: orderData.id,
            referenceType: 'SALES_ORDER'
          });
        }

        // Check if sent to Xero
        if (!oldData.xeroSent && orderData.xeroSent) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.SALES_ORDER,
            title: 'Sales Order Sent to Xero',
            message: `Sales order #${orderData.orderId} has been sent to Xero`,
            referenceId: orderData.id,
            referenceType: 'SALES_ORDER'
          });
        }
      }
    });
  });

  listeners.push(salesOrdersUnsubscribe);

  // Purchase Orders Listener
  const purchaseOrdersQuery = query(
    purchaseOrdersRef,
    where('companyId', '==', companyId),
    orderBy('createdAt', 'desc')
  );

  const purchaseOrdersUnsubscribe = onSnapshot(purchaseOrdersQuery, (snapshot) => {
    snapshot.docChanges().forEach(async (change) => {
      const orderData = { id: change.doc.id, ...change.doc.data() };

      if (change.type === 'added') {
        await createNotification({
          userId,
          companyId,
          type: NOTIFICATION_TYPES.PURCHASE_ORDER,
          title: 'New Purchase Order Created',
          message: `Purchase order #${orderData.orderId} created for ${orderData.supplierName}`,
          referenceId: orderData.id,
          referenceType: 'PURCHASE_ORDER'
        });
      } else if (change.type === 'modified') {
        const oldData = change.doc.data();
        
        if (oldData.status !== orderData.status) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.PURCHASE_ORDER,
            title: 'Purchase Order Status Updated',
            message: `Purchase order #${orderData.orderId} status changed to ${orderData.status}`,
            referenceId: orderData.id,
            referenceType: 'PURCHASE_ORDER'
          });
        }

        if (!oldData.xeroSent && orderData.xeroSent) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.PURCHASE_ORDER,
            title: 'Purchase Order Sent to Xero',
            message: `Purchase order #${orderData.orderId} has been sent to Xero`,
            referenceId: orderData.id,
            referenceType: 'PURCHASE_ORDER'
          });
        }
      }
    });
  });

  listeners.push(purchaseOrdersUnsubscribe);

  // Inventory Listener
  const inventoryQuery = query(
    inventoryRef,
    where('companyId', '==', companyId)
  );

  const inventoryUnsubscribe = onSnapshot(inventoryQuery, (snapshot) => {
    snapshot.docChanges().forEach(async (change) => {
      const itemData = { id: change.doc.id, ...change.doc.data() };

      if (change.type === 'added') {
        await createNotification({
          userId,
          companyId,
          type: NOTIFICATION_TYPES.INVENTORY,
          title: 'New Inventory Item Added',
          message: `New item "${itemData.name}" has been added to inventory`,
          referenceId: itemData.id,
          referenceType: 'INVENTORY'
        });
      } else if (change.type === 'modified') {
        const oldData = change.doc.data();
        const changes = {};

        // Check what fields changed
        ['quantity', 'cost_price', 'sell_price', 'reorder_point'].forEach(field => {
          if (oldData[field] !== itemData[field]) {
            changes[field] = itemData[field];
          }
        });

        if (Object.keys(changes).length > 0) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.INVENTORY,
            title: 'Inventory Item Updated',
            message: getInventoryUpdateMessage(itemData.name, changes),
            referenceId: itemData.id,
            referenceType: 'INVENTORY'
          });
        }

        // Check for low stock
        if (itemData.quantity <= itemData.reorder_point) {
          await createNotification({
            userId,
            companyId,
            type: NOTIFICATION_TYPES.INVENTORY,
            title: 'Low Stock Alert',
            message: `${itemData.name} has fallen below the reorder point (${itemData.quantity} remaining)`,
            referenceId: itemData.id,
            referenceType: 'INVENTORY'
          });
        }
      }
    });
  });

  listeners.push(inventoryUnsubscribe);

  // Return cleanup function
  return () => {
    listeners.forEach(unsubscribe => unsubscribe());
  };
};

// Helper function for inventory update messages
const getInventoryUpdateMessage = (itemName, changes) => {
  const messages = [];
  
  if (changes.quantity !== undefined) {
    messages.push(`quantity updated to ${changes.quantity}`);
  }
  if (changes.cost_price !== undefined) {
    messages.push(`cost price updated to $${changes.cost_price}`);
  }
  if (changes.sell_price !== undefined) {
    messages.push(`sell price updated to $${changes.sell_price}`);
  }
  if (changes.reorder_point !== undefined) {
    messages.push(`reorder point updated to ${changes.reorder_point}`);
  }

  return `${itemName}: ${messages.join(', ')}`;
};

// Export other notification functions (getNotifications, markAsRead, etc.)
export {
  createNotification,
  getNotifications,
  markAsRead,
  markAllAsRead
};
import React from 'react';
import ReactApexChart from 'react-apexcharts';

// Stock Level Distribution Chart
export const StockLevelDonut = ({ stockLevels }) => {
  const options = {
    chart: {
      type: 'donut',
    },
    labels: ['Normal Stock', 'Moderate Stock', 'Low Stock', 'Out of Stock'],
    colors: ['#10B981', '#FBBF24', '#EF4444', '#6B7280'],
    legend: {
      position: 'bottom'
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total Items',
            }
          }
        }
      }
    }
  };

  return (
    <div className="chart-card">
      <h3>Stock Level Distribution</h3>
      <ReactApexChart
        options={options}
        series={stockLevels}
        type="donut"
        height={350}
      />
    </div>
  );
};

// Low Stock Items Bar Chart
export const LowStockBar = ({ lowStockItems }) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4
      }
    },
    colors: ['#EF4444', '#6B7280'],
    xaxis: {
      categories: lowStockItems.map(item => item.name)
    },
    yaxis: {
      title: {
        text: 'Quantity'
      }
    },
    legend: {
      position: 'top'
    }
  };

  const series = [
    {
      name: 'Current Stock',
      data: lowStockItems.map(item => item.quantity)
    },
    {
      name: 'Reorder Point',
      data: lowStockItems.map(item => item.reorderPoint)
    }
  ];

  return (
    <div className="chart-card">
      <h3>Top 10 Low Stock Items</h3>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

// Monthly Gross Profit Trend
export const ProfitTrendLine = ({ profitData }) => {
  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: [4, 4, 2]
    },
    colors: ['#10B981', '#EF4444', '#6B7280'],
    xaxis: {
      categories: profitData.labels,
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'Amount ($)'
      },
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    legend: {
      position: 'top'
    }
  };

  return (
    <div className="chart-card">
      <h3>Monthly Gross Profit Trend</h3>
      <ReactApexChart
        options={options}
        series={profitData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

// Category Performance Chart
export const CategoryPerformance = ({ categoryData }) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: true
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4
      }
    },
    xaxis: {
      categories: categoryData.categories
    },
    yaxis: {
      title: {
        text: 'Amount ($)'
      },
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    colors: ['#10B981', '#3B82F6'],
    legend: {
      position: 'top'
    }
  };

  return (
    <div className="chart-card">
      <h3>Category Performance</h3>
      <ReactApexChart
        options={options}
        series={categoryData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

function StockSettings() {
  const [moderateThreshold, setModerateThreshold] = useState(0);
  const [lowThreshold, setLowThreshold] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchStockSettings();
  }, []);

  const fetchStockSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', currentUser.uid));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setModerateThreshold(data.moderateThreshold);
        setLowThreshold(data.lowThreshold);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const saveStockSettings = async () => {
    try {
      await setDoc(doc(db, 'settings', currentUser.uid), {
        moderateThreshold,
        lowThreshold
      });
      alert("Settings saved successfully!");
    } catch (error) {
      console.error("Error saving settings:", error);
      alert("Failed to save settings. Please try again.");
    }
  };

  return (
    <div className="StockSettings-container">
      <h3>Minimum Stock Thresholds</h3>
      <div>
        <label>
          Moderate Stock Threshold:
          <input
            type="number"
            value={moderateThreshold}
            onChange={(e) => setModerateThreshold(Number(e.target.value))}
          />
        </label>
      </div>
      <div>
        <label>
          Low Stock Threshold:
          <input
            type="number"
            value={lowThreshold}
            onChange={(e) => setLowThreshold(Number(e.target.value))}
          />
        </label>
      </div>
      <button onClick={saveStockSettings}>Save</button>
    </div>
  );
}

export default StockSettings;
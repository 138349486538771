import React from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionError = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6 bg-white rounded-lg shadow max-w-2xl mx-auto mt-8">
      <h2 className="text-xl font-semibold mb-4">Subscription Error</h2>
      
      <div className="space-y-4">
        <div className="text-red-600">
          There was an error processing your subscription.
        </div>

        <p className="text-gray-600">
          The subscription process couldn't be completed. This could be due to:
        </p>

        <ul className="list-disc list-inside text-gray-600 ml-4">
          <li>A payment processing issue</li>
          <li>A cancelled checkout session</li>
          <li>A technical error</li>
        </ul>

        <div className="pt-4 space-y-4">
          <button
            onClick={() => navigate('/subscription')}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          >
            Try Again
          </button>

          <p className="text-sm text-gray-500">
            If you continue to experience issues, please contact support for assistance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionError;
import axios from "axios";
import { refreshAccessToken } from "./xeroAuth";
import { jwtDecode } from "jwt-decode";

// const API_URL = "http://localhost:5001/onev-8da8c/us-central1/api";
const API_URL = "https://us-central1-onev-8da8c.cloudfunctions.net/api";

export const getXeroContacts = async () => {
  try {
    const { accessToken, tenantId } = await getXeroTokenAndTenant();
    const response = await axios.get(
      `${API_URL}/xero-contacts?accessToken=${accessToken}&tenantId=${tenantId}`,
      {
        credentials: "include",
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to fetch Xero contacts:", error);
    throw error;
  }
};

export const createXeroSalesOrder = async (orderData) => {
  try {
    const { accessToken, tenantId } = await getXeroTokenAndTenant();
    const response = await axios.post(
      `${API_URL}/xero-sales-order?accessToken=${accessToken}&tenantId=${tenantId}`,
      { orderData: JSON.stringify(orderData) },
      {
        credentials: "include",
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating sales order in Xero:", error);
    throw error;
  }
};

export const createXeroPurchaseOrder = async (orderData) => {
  try {
    const { accessToken, tenantId } = await getXeroTokenAndTenant();
    const response = await axios.post(
      `${API_URL}/xero-purchase-order?accessToken=${accessToken}&tenantId=${tenantId}`,
      { orderData: JSON.stringify(orderData) },
      {
        credentials: "include",
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating purchase order in Xero:", error);
    throw error;
  }
};

const getXeroTokenAndTenant = async () => {
  const tokenString = localStorage.getItem("xeroToken");
  const defaultTenantString = localStorage.getItem("xeroDefaultTenant");

  if (!tokenString) {
    throw new Error("No Xero token found");
  }
  let token = JSON.parse(tokenString);

  // check if token expired
  if (isTokenExpired(token.access_token)) {
    const newTokens = await refreshAccessToken(token.refresh_token);
    localStorage.setItem("xeroToken", JSON.stringify(newTokens));
    token = newTokens;
  }

  if (!defaultTenantString) {
    throw new Error("No default tenant found for Xero");
  }
  const defaultTenant = JSON.parse(defaultTenantString);

  return { accessToken: token.access_token, tenantId: defaultTenant.tenantId };
};

function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    if (!decoded || !decoded.exp) return true; // Token has no exp field or is malformed

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Token decoding failed:", error);
    return true; // Consider token expired if decoding fails
  }
}

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  header: {
    marginBottom: 20,
    borderBottom: 1,
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    color: '#2563eb',
  },
  companyInfo: {
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 5,
  },
  label: {
    width: 120,
    fontWeight: 'bold',
  },
  table: {
    marginTop: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f3f4f6',
    borderBottomWidth: 1,
    borderColor: '#000',
    paddingVertical: 8,
    paddingHorizontal: 5,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 5,
    borderBottomWidth: 0.5,
    borderColor: '#cbd5e1',
  },
  col1: { width: '40%' },
  col2: { width: '20%', textAlign: 'center' },
  col3: { width: '20%', textAlign: 'right' },
  col4: { width: '20%', textAlign: 'right' },
  total: {
    marginTop: 20,
    paddingTop: 10,
    borderTopWidth: 1,
    borderColor: '#000',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 5,
  },
  totalLabel: {
    fontWeight: 'bold',
    marginRight: 40,
  },
  totalValue: {
    width: 100,
    textAlign: 'right',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    paddingTop: 10,
    borderTopWidth: 1,
    borderColor: '#cbd5e1',
    fontSize: 10,
    color: '#64748b',
  },
});

const OrderPDF = ({ order, type }) => {
  const isSupplier = type === 'purchase';
  const title = isSupplier ? 'Purchase Order' : 'Sales Order';
  const partyLabel = isSupplier ? 'Supplier' : 'Customer';
  const partyName = isSupplier ? order.supplierName : order.customerName;
  
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD'
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
          <View style={styles.companyInfo}>
            <Text>Oneventory</Text>
            {/* Add your company details here */}
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.label}>Order ID:</Text>
            <Text>{order.orderId}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>{partyLabel}:</Text>
            <Text>{partyName}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Date Created:</Text>
            <Text>{formatDate(order.createdAt)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Due Date:</Text>
            <Text>{formatDate(order.dueDate)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Status:</Text>
            <Text>{order.status}</Text>
          </View>
          {order.comments && (
            <View style={styles.row}>
              <Text style={styles.label}>Comments:</Text>
              <Text>{order.comments}</Text>
            </View>
          )}
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.col1}>Item</Text>
            <Text style={styles.col2}>Quantity</Text>
            <Text style={styles.col3}>Price</Text>
            <Text style={styles.col4}>Total</Text>
          </View>
          
          {order.items.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.col1}>{item.name}</Text>
              <Text style={styles.col2}>{item.quantity}</Text>
              <Text style={styles.col3}>{formatCurrency(item.price)}</Text>
              <Text style={styles.col4}>{formatCurrency(item.quantity * item.price)}</Text>
            </View>
          ))}
        </View>

        <View style={styles.total}>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Subtotal:</Text>
            <Text style={styles.totalValue}>{formatCurrency(order.totalAmount)}</Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>GST (10%):</Text>
            <Text style={styles.totalValue}>{formatCurrency(order.totalAmount * 0.1)}</Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total:</Text>
            <Text style={styles.totalValue}>{formatCurrency(order.totalAmount * 1.1)}</Text>
          </View>
        </View>

        <View style={styles.footer}>
          <Text>Generated via Oneventory - {new Date().toLocaleString()}</Text>
        </View>
      </Page>
    </Document>
  );
};

export const generatePDF = async (order, type) => {
  return await pdf(<OrderPDF order={order} type={type} />).toBlob();
};

export default OrderPDF;
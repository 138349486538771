import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

function CompanySwitcher({ onCompanyChange }) {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      fetchUserCompany();
    }
  }, [currentUser]);

  const fetchUserCompany = async () => {
    setLoading(true);
    setError(null);
    try {
     // console.log('Fetching company for user:', currentUser.uid);
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      
      if (!userDoc.exists()) {
        //console.log('User document not found');
        setError('User document not found');
        setLoading(false);
        return;
      }

      const userData = userDoc.data();
      //console.log('User data:', userData);

      if (!userData.companyId) {
       // console.log('No company ID found for user');
        setLoading(false);
        return;
      }

      const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
      
      if (!companyDoc.exists()) {
        //console.log('Company document not found');
        setError('Company not found');
        setLoading(false);
        return;
      }

      const companyData = { id: companyDoc.id, ...companyDoc.data() };
      //console.log('Fetched company:', companyData);
      setCompany(companyData);
      onCompanyChange(companyData.id);
    } catch (err) {
      console.error('Error fetching company:', err);
      setError('Failed to fetch company');
    }
    setLoading(false);
  };

  if (loading) return <div>Loading company...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!company) return <div>No company found</div>;

  return (
    <div></div>
  );
}

export default CompanySwitcher;
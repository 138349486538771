import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const useChartData = (companyId) => {
  const [chartData, setChartData] = useState({
    stockLevels: [0, 0, 0, 0],
    lowStockItems: [],
    profitData: {
      labels: [],
      series: []
    },
    categoryData: {
      categories: [],
      series: []
    }
  });

  const processStockLevels = (items) => {
    const levels = [0, 0, 0, 0]; // Normal, Moderate, Low, Out
    
    items.forEach(item => {
      if (item.quantity === 0) {
        levels[3]++;
      } else if (item.quantity <= item.reorderPoint) {
        levels[2]++;
      } else if (item.quantity <= item.reorderPoint * 2) {
        levels[1]++;
      } else {
        levels[0]++;
      }
    });

    return levels;
  };

  const processLowStockItems = (items) => {
    return items
      .filter(item => item.quantity <= item.reorderPoint)
      .sort((a, b) => (a.quantity / a.reorderPoint) - (b.quantity / b.reorderPoint))
      .slice(0, 10)
      .map(item => ({
        name: item.name,
        quantity: item.quantity,
        reorderPoint: item.reorderPoint
      }));
  };

  const processProfitData = async () => {
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      return date.toLocaleDateString('en-US', { month: 'short' });
    }).reverse();

    // Fetch sales and purchase data for the last 6 months
    // You'll need to implement this based on your data structure
    
    return {
      labels: last6Months,
      series: [
        {
          name: 'Revenue',
          data: [] // Populate with actual data
        },
        {
          name: 'Cost',
          data: [] // Populate with actual data
        },
        {
          name: 'Profit',
          data: [] // Calculate from revenue and cost
        }
      ]
    };
  };

  const processCategoryData = (items) => {
    const categories = {};
    
    items.forEach(item => {
      if (!categories[item.category]) {
        categories[item.category] = {
          sales: 0,
          profit: 0
        };
      }
      
      const sales = item.quantity * item.sell_price;
      const cost = item.quantity * item.cost_price;
      categories[item.category].sales += sales;
      categories[item.category].profit += (sales - cost);
    });

    return {
      categories: Object.keys(categories),
      series: [
        {
          name: 'Sales',
          data: Object.values(categories).map(c => c.sales)
        },
        {
          name: 'Profit',
          data: Object.values(categories).map(c => c.profit)
        }
      ]
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!companyId) return;

      try {
        const inventoryQuery = query(
          collection(db, 'inventory'),
          where('companyId', '==', companyId)
        );
        
        const inventorySnapshot = await getDocs(inventoryQuery);
        const items = inventorySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setChartData({
          stockLevels: processStockLevels(items),
          lowStockItems: processLowStockItems(items),
          profitData: await processProfitData(),
          categoryData: processCategoryData(items)
        });
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, [companyId]);

  return chartData;
};
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { Menu, Search, Bell, Settings, X, Check, CircleUser, LogOut, Plus, ArrowBigDown, ArrowBigUp} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { 
    getNotifications, 
    markAsRead, 
    markAllAsRead 
  } from '../../services/notificationService';
  import ResponsiveNav from './ResponsiveNav';
  import { Home, PackageOpen, ShoppingCart, Truck, NotebookTabs, User} from 'lucide-react';
  import { signOut } from 'firebase/auth';
  import EnhancedOrderSidebar from '../OrderSidebar/EnhancedOrderSidebar';
  import { useSubscriptionStatus } from '../../hooks/subscription';


  const Header = () => {
    const { isTrialing, trialEnd, isSubscriptionValid } = useSubscriptionStatus();
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [showNotifications, setShowNotifications] = useState(false);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const notificationsRef = useRef(null);
    const [isOrderSidebarOpen, setIsOrderSidebarOpen] = useState(false);
    const [orderSidebarType, setOrderSidebarType] = useState(null);
    const [currentOrderDetails, setCurrentOrderDetails] = useState({
      orderId: '',
      dueDate: '',
      customerSupplierName: ''
    });
    
    const handleNewOrder = (type) => {
      setOrderSidebarType(type);
      setCurrentOrderDetails({
        orderId: '',
        dueDate: '',
        customerSupplierName: ''
      });
      setIsOrderSidebarOpen(true);
    };


  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleSettings = () => {
    navigate('/my-account');
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!currentUser?.companyId) return;
      
      setLoading(true);
      try {
        const fetchedNotifications = await getNotifications(
          currentUser.uid, 
          currentUser.companyId,
          { limit: 20 }
        );
        setNotifications(fetchedNotifications);
        setUnreadCount(fetchedNotifications.filter(n => !n.read).length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
      setLoading(false);
    };

    fetchNotifications();
    // Set up real-time updates if needed
  }, [currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      // Implement your search logic here
      console.log('Searching for:', searchQuery);
    }
  };

  const handleNotificationClick = async (notification) => {
    if (!notification.read) {
      await markAsRead(notification.id);
      setNotifications(notifications.map(n => 
        n.id === notification.id ? { ...n, read: true } : n
      ));
      setUnreadCount(prev => prev - 1);
    }

    // Navigate based on notification type
    if (notification.referenceType && notification.referenceId) {
      navigate(`/${notification.referenceType.toLowerCase()}/${notification.referenceId}`);
    }
  };

  const handleMarkAllRead = async () => {
    if (!currentUser?.companyId) return;
    
    await markAllAsRead(currentUser.uid, currentUser.companyId);
    setNotifications(notifications.map(n => ({ ...n, read: true })));
    setUnreadCount(0);
  };

  const formatNotificationTime = (date) => {
    const now = new Date();
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else if (days < 7) {
      return `${days}d ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  const menuItems = [
    { path: '/', icon: <Home />, text: 'Home' },
    { path: '/inventory', icon: <PackageOpen />, text: 'Inventory' },
    { path: '/sales-orders', icon: <ShoppingCart />, text: 'Sales Orders' },
    { path: '/purchase-orders', icon: <Truck />, text: 'Purchase Orders' },
    { path: '/contacts', icon: <NotebookTabs />, text: 'Contacts' },
    { path: '/my-account', icon: <User />, text: 'My Account' },
  ];

  return (
    <div>
    <header className="header">

      {isTrialing && (
        <div className="alert">
          Your trial ends on {trialEnd.toLocaleDateString()}
        </div>
      )}

      <div className="header-left">
       
        <Link to="/" className="logo">
        <img src="../ONEV_nobg.png" alt="Oneventory" className="nav-logo" />
        </Link>
      </div>

      {/*
      <div className="search-container">
        {showSearch && (
          <>
            <Search size={20} className="search-icon" />
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleSearch}
              className="search-input"
            />
            <button 
              className="close-search"
              onClick={() => setShowSearch(false)}
            >
              <X size={20} />
            </button>
          </>
        )}
        {!showSearch && (
          <button 
            className="search-button"
            onClick={() => setShowSearch(true)}
          >
            <Search size={24} />
          </button>
        )}
      </div>
       */}

      <div className="header-right">

        <button 
            onClick={() => handleNewOrder('sales')}
            className="new-order-button sales"
          >
            <ArrowBigUp className="icon" />
            New Sales Order
        </button>
        <button 
          onClick={() => handleNewOrder('purchase')}
          className="new-order-button purchase"
        >
          <ArrowBigDown className="icon" />
          New Purchase Order
        </button>

        <div className="notifications-container" ref={notificationsRef}>
          <button 
            className="notification-button"
            onClick={() => setShowNotifications(!showNotifications)}
          >
            <Bell size={24} />
            {unreadCount > 0 && (
              <span className="notification-badge">
                {unreadCount}
              </span>
            )}
          </button>


          
          {showNotifications && (
            <div className="notifications-dropdown">
              <div className="notifications-header">
                <h3>Recent Activity</h3>
                {unreadCount > 0 && (
                  <button 
                    className="mark-all-read"
                    onClick={handleMarkAllRead}
                  >
                    Mark all as read
                  </button>
                )}
              </div>

              {loading ? (
                <div className="notifications-loading">Loading...</div>
              ) : notifications.length > 0 ? (
                <div className="notifications-list">
                  {notifications.map((notification) => (
                    <div 
                      key={notification.id}
                      className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="notification-content">
                        <h4>{notification.title}</h4>
                        <p>{notification.message}</p>
                        <span className="notification-time">
                          {formatNotificationTime(notification.createdAt)}
                        </span>
                      </div>
                      {!notification.read && (
                        <div className="unread-indicator" />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-notifications">No notifications</p>
              )}
            </div>
          )}
        </div>
        
        <button 
          className="settings-button"
          onClick={handleSettings}
        >
          <Settings size={24} />
        </button>

        <Link to="/my-account" className="profile-link">
          {currentUser?.photoURL ? (
            <img 
              src={currentUser.photoURL} 
              alt="Profile" 
              className="profile-photo"
            />
          ) : (
            <div className="profile-placeholder">
              <CircleUser size={24} />
            </div>
          )}
        </Link>

        <button onClick={handleSignOut} className="sign-out-button">
             <LogOut/>
          </button>

        </div>

       
    </header>

          
    <ResponsiveNav menuItems={menuItems} />

    <EnhancedOrderSidebar
        showSidebar={isOrderSidebarOpen}
        setShowSidebar={setIsOrderSidebarOpen}
        sidebarType={orderSidebarType}
        orderDetails={currentOrderDetails}
        handleOrderDetailsChange={(e) => {
          setCurrentOrderDetails(prev => ({
            ...prev,
            [e.target.name]: e.target.value
          }));
        }}
        salesOrder={{}} // Your sales order data
        purchaseOrder={{}} // Your purchase order data
        items={[]} // Your items data
        removeFromOrder={() => {}} // Your remove function
        createOrder={() => {}} // Your create function
        formatPrice={(price) => price.toFixed(2)} // Your format function
        companyId={currentUser.companyId} // Your company ID
        onUpdateOrder={() => {}} // Your update function
      />

    </div>
  );
}

export default Header;
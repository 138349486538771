import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateSubscriptionStatus = async () => {
      if (!currentUser) {
        console.error('No current user found');
        return;
      }

      try {
        // Update the users collection instead of customers
        const userRef = doc(db, 'users', currentUser.uid);
        
        await updateDoc(userRef, {
          status: 'active',
          subscriptionUpdatedAt: serverTimestamp(),
          // You might want to set trial end to null if they're now a paid subscriber
          trialEnd: null
        });
        
        console.log('Successfully updated subscription status');
        navigate('/');
      } catch (err) {
        console.error('Error updating subscription status:', err);
        setError('Failed to complete subscription setup. Please contact support.');
      }
    };

    updateSubscriptionStatus();
  }, [currentUser, navigate]);

  if (error) {
    return (
      <div className="p-6">
        <div className="text-red-500">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-4">Setting up your subscription...</h2>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
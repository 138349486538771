import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

async function getCompanyIdForUser(userId) {
  try {
    // Assuming we have a 'users' collection where each document ID is the user's UID
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      
      // Assuming the company ID is stored in a field called 'companyId'
      // If it's stored differently, adjust this line accordingly
      const companyId = userData.companyId;
      //console.log('Company ID for user:', userId, 'is:', companyId);

      if (companyId) {
        return companyId;
      } else {
        console.error('No company ID found for user:', userId);
        return null;
      }
    } else {
      console.error('No user document found for ID:', userId);
      return null;
    }
  } catch (error) {
    console.error('Error fetching company ID:', error);
    return null;
  }
}

export default getCompanyIdForUser;
import React, { useState }  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, getAdditionalUserInfo, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, googleProvider } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

function Welcome() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const additionalUserInfo = getAdditionalUserInfo(result);
      if (additionalUserInfo.isNewUser) {
        navigate('/signup', { state: { fromGoogle: true } });
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  if (currentUser) {
    return navigate("/");
  }

  return (
    <div className="welcome-page">
      <nav className="welcome-nav">
        <div className="logo-container">
          <img src="../ONEV_nobg.png" alt="Oneventory" className="nav-logo" />
        </div>
      </nav>

      <div className="hero-section">
        <div className="hero-content">
          <div className="badge">
            <span className="badge-star">★</span>
            <span className="badge-text">Best Inventory Management Solution</span>
          </div>

          <h1 className="hero-title">
            Simplify Your
            <br />
            Inventory Management
          </h1>

          <p className="hero-subtitle">
            Oneventory is your all-in-one solution for modern inventory management.
          </p>

          <div className="hero-buttons">
            <Link to="/signup" className="primary-button">
              Sign Up
              <svg className="arrow-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Link>
            <button onClick={handleGoogleSignIn} className="google-button">
              <img src="https://developers.google.com/static/identity/images/g-logo.png" alt="Google" className="google-icon" />
              Sign in with Google
            </button>
          </div>
        </div>

        <div className="hero-decoration">
          <div className="signup-container">
            <h2>Sign In</h2>
            <form onSubmit={handleSignIn}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
              <button type="submit">Sign In</button>
            </form>
            
            {error && <p className="error-message">{error}</p>}
          </div>

        </div>
      </div>

    </div>
  );
}

export default Welcome;
import React from 'react';
import { Info } from 'lucide-react';

const TableHeader = () => {
  const headers = [
    {
      label: 'Name',
      tooltip: 'The name or description of the inventory item'
    },
    {
      label: 'SKU',
      tooltip: 'Stock Keeping Unit - A unique identifier for this item'
    },
    {
      label: 'Category',
      tooltip: 'The category or group this item belongs to'
    },
    {
      label: 'Stock on Hand',
      tooltip: 'Current quantity available in inventory. Updates automatically with sales and purchases'
    },
    {
      label: 'Cost Price',
      tooltip: 'The price paid to suppliers for this item (excluding tax)'
    },
    {
      label: 'Sell Price',
      tooltip: 'The price at which this item is sold to customers (excluding tax)'
    },
    {
      label: 'Margin',
      tooltip: 'The dollar difference between sell price and cost price'
    },
    {
      label: 'Margin (%)',
      tooltip: 'The percentage markup on cost price ((sell price - cost price) / cost price * 100)'
    },
    {
      label: 'Location',
      tooltip: 'Physical storage location of the item in your warehouse or store'
    },
    {
      label: 'Actions',
      tooltip: 'Available operations for this item'
    }
  ];

  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={index}>
            <div className="header-content">
              {header.label}
              {header.tooltip && (
                <div className="tooltip-container">
                  <Info size={14} className="info-icon" />
                  <div className="tooltip-text">
                    {header.tooltip}
                  </div>
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { exchangeCodeForToken, getXeroTenants } from "../utils/xeroAuth";

function XeroCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          const tokenData = await exchangeCodeForToken(code);
          localStorage.setItem("xeroToken", JSON.stringify(tokenData));

          if (tokenData) {
            const accessToken = tokenData.access_token;
            const allTenants = await getXeroTenants(accessToken);
            if (allTenants && allTenants.length > 0) {
              localStorage.setItem(
                "xeroDefaultTenant",
                JSON.stringify(allTenants.at(0))
              );
            }
          }

          navigate("/"); // Redirect to home page or wherever the XeroConnect component is
        } catch (error) {
          console.error("Error during Xero authentication:", error);
          navigate("/");
        }
      } else {
        console.error("No code found in URL");
        navigate("/");
      }
    };

    handleCallback();
  }, []);

  return <div>Authenticating with Xero...</div>;
}

export default XeroCallback;

import React, { useState, useEffect, useRef } from 'react';
import {collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { Plus, X, Edit2, Trash2 } from 'lucide-react';
import './OrderSidebar.css';

const EnhancedOrderSidebar = ({
  showSidebar,
  setShowSidebar,
  sidebarType,
  orderDetails,
  handleOrderDetailsChange,
  salesOrder,
  purchaseOrder,
  items,
  removeFromOrder,
  createOrder,
  formatPrice,
  companyId,
  onUpdateOrder,
}) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    id: '',
    name: '',
    quantity: 1,
    price: 0
  });
  const [removingProduct, setRemovingProduct] = useState(null);
  const order = sidebarType === 'sales' ? salesOrder : purchaseOrder;

  useEffect(() => {
    if (showSidebar && !orderDetails.dueDate && companyId)  {
      fetchContacts();
      fetchAvailableProducts();
      handleOrderDetailsChange({
        target: {
          name: 'dueDate',
          value: getDefaultDueDate()
        }
      });
    }
  }, [companyId, sidebarType, showSidebar]);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if sidebar is shown and click is outside
      if (showSidebar && 
          sidebarRef.current && 
          !sidebarRef.current.contains(event.target)) {
        setShowSidebar(false);
      }
    };

    // Add event listener when sidebar is shown
    if (showSidebar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSidebar, setShowSidebar]);

  const fetchContacts = async () => {
    if (!companyId) {
      setError('Company ID is required');
      return;
    }

    try {
      const contactsRef = collection(db, 'contacts');
      const q = query(
        contactsRef,
        where('companyId', '==', companyId),
        where(sidebarType === 'sales' ? 'isCustomer' : 'isSupplier', '==', true)
      );
      
      const querySnapshot = await getDocs(q);
      const contactsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContacts(contactsList);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Error loading contacts');
    }
  };

  const fetchAvailableProducts = async () => {
    if (!companyId) {
      setError('Company ID is required');
      return;
    }
    //console.log("Fetching available products for company ID:", companyId);
  
    setIsLoading(true);
    try {
      const productsRef = collection(db, 'inventory');
      const q = query(productsRef, where('companyId', '==', companyId));
      const querySnapshot = await getDocs(q);
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableProducts(productsList);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Error loading products');
    } finally {
      setIsLoading(false);
    }
  };


  const handleAddProduct = () => {
    if (!newProduct.id) return;
    
    const product = availableProducts.find(p => p.id === newProduct.id);
    const updatedOrder = {
      ...order,
      [newProduct.id]: newProduct.quantity
    };
    
    onUpdateOrder(updatedOrder, sidebarType);
    setNewProduct({ id: '', name: '', quantity: 1, price: 0 });
    setShowAddProduct(false);
  };

  const handleContactSearch = (e) => {
    const searchValue = e.target.value;
    handleOrderDetailsChange({
      target: {
        name: 'customerSupplierName',
        value: searchValue
      }
    });

    const filtered = contacts.filter(contact => 
      contact.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredContacts(filtered);
    setShowDropdown(true);
  };

  const handleContactSelect = (contact) => {
    handleOrderDetailsChange({
      target: {
        name: 'customerSupplierName',
        value: contact.name
      }
    });
    setShowDropdown(false);
  };

  const handleProductEdit = (product) => {
    setEditingProduct({
      ...product,
      quantity: order[product.id] || 1,
      price: sidebarType === 'sales' ? product.sell_price : product.cost_price
    });
  };

  const getDefaultDueDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const handleProductUpdate = (updatedProduct) => {
    const updatedOrder = {
      ...order,
      [updatedProduct.id]: updatedProduct.quantity
    };
    onUpdateOrder(updatedOrder, sidebarType);
    setEditingProduct(null);
  };

  /**
  const handleRemoveFromOrder = (productId, type) => {
    if (type === 'sales') {
      setSalesOrder(prevOrder => {
        const newOrder = { ...prevOrder };
        delete newOrder[productId];
        return newOrder;
      });
    } else {
      setPurchaseOrder(prevOrder => {
        const newOrder = { ...prevOrder };
        delete newOrder[productId];
        return newOrder;
      });
    }
  };  */

  const calculateTotal = () => {
    return Object.entries(order).reduce((total, [productId, quantity]) => {
      const product = items.find(item => item.id === productId);
      if (!product) return total;
      const price = sidebarType === 'sales' ? product.sell_price : product.cost_price;
      return total + (quantity * price);
    }, 0);
  };

  return (
    <>
      {/* Semi-transparent overlay */}
      {showSidebar && (
        <div className="sidebar-overlay" />
      )}
      
      {/* Main sidebar */}
      <div 
        ref={sidebarRef}
        className={`sidebar ${showSidebar ? 'show' : ''}`}
      >
        <div className="sidebar-content">
        {/* Header */}
        <div className="sidebar-header">
          <h2 className="sidebar-title">
            {sidebarType === 'sales' ? 'Sales Order' : 'Purchase Order'}
          </h2>
          <button className="close-button" onClick={() => setShowSidebar(false)}>
            <X size={20} />
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="error-message">{error}</div>
        )}

        {/* Order Details Card */}
        <div className="card">

        <div className="form-group">
            <label className="form-label">
              {sidebarType === 'sales' ? 'Customer' : 'Supplier'}
            </label>
            <div className="autocomplete-container">
              <input
                type="text"
                name="customerSupplierName"
                value={orderDetails.customerSupplierName}
                onChange={handleContactSearch}
                className="form-input"
                placeholder={`Search ${sidebarType === 'sales' ? 'customer' : 'supplier'}...`}
              />
              {showDropdown && filteredContacts.length > 0 && (
                <div className="autocomplete-dropdown">
                  {filteredContacts.map(contact => (
                    <div
                      key={contact.id}
                      className="autocomplete-item"
                      onClick={() => handleContactSelect(contact)}
                    >
                      <div className="contact-name">{contact.name}</div>
                      <div className="contact-details">
                        {contact.email && <span>{contact.email}</span>}
                        {contact.phone && <span>{contact.phone}</span>}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label className="form-label">Reference</label>
            <input
              type="text"
              name="orderId"
              value={orderDetails.orderId}
              onChange={handleOrderDetailsChange}
              className="form-input"
              placeholder="Enter order ID"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Due Date</label>
            <input
              type="date"
              name="dueDate"
              value={orderDetails.dueDate}
              onChange={handleOrderDetailsChange}
              className="form-input"
            />
          </div>

          
        </div>

        {/* Products Section */}
        <div className="products-header">
          <h3 className="section-title">Products</h3>
          <button className="add-button" onClick={() => setShowAddProduct(true)}>
            <Plus size={16} />
            Add Product
          </button>
        </div>

        {/* Add Product Form */}
        {showAddProduct && (
          <div className="card">
            <select
              className="form-select"
              value={newProduct.id}
              onChange={(e) => {
                const product = availableProducts.find(p => p.id === e.target.value);
                setNewProduct({
                  id: e.target.value,
                  name: product?.name || '',
                  quantity: 1,
                  price: sidebarType === 'sales' ? product?.sell_price : product?.cost_price
                });
              }}
            >
              <option value="">Select Product</option>
              {availableProducts.map(product => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>

            <div className="form-group">
              <input
                type="number"
                min="1"
                value={newProduct.quantity}
                onChange={(e) => setNewProduct({
                  ...newProduct,
                  quantity: parseInt(e.target.value) || 1
                })}
                className="form-input"
                placeholder="Quantity"
              />
            </div>

            <div className="edit-controls">
              <button className="add-button" onClick={() => setShowAddProduct(false)}>
                Cancel
              </button>
              <button
                className="create-order-button"
                onClick={() => {
                  const updatedOrder = {
                    ...order,
                    [newProduct.id]: newProduct.quantity
                  };
                  onUpdateOrder(updatedOrder, sidebarType);
                  setNewProduct({ id: '', name: '', quantity: 1, price: 0 });
                  setShowAddProduct(false);
                }}
                disabled={!newProduct.id}
              >
                Add
              </button>
            </div>
          </div>
        )}

        {/* Product List */}
        {Object.entries(order).map(([productId, quantity]) => {
          const product = items.find(item => item.id === productId);
          if (!product) return null;

          return (
            <div key={productId} className="product-item">
              {editingProduct?.id === productId ? (
                <div className="edit-mode">
                  <input
                    type="number"
                    min="1"
                    value={editingProduct.quantity}
                    onChange={(e) => setEditingProduct({
                      ...editingProduct,
                      quantity: parseInt(e.target.value) || 1
                    })}
                    className="form-input"
                  />
                  <div className="edit-controls">
                    <button className="add-button" onClick={() => setEditingProduct(null)}>
                      Cancel
                    </button>
                    <button
                      className="create-order-button"
                      onClick={() => handleProductUpdate(editingProduct)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="product-info">
                    <div className="product-name">{product.name}</div>
                    <div className="product-quantity">
                      Quantity: {quantity} × ${formatPrice(sidebarType === 'sales' ? product.sell_price : product.cost_price)}
                    </div>
                  </div>
                  <div className="product-actions">
                    <button
                      className="action-button"
                      onClick={() => handleProductEdit(product)}
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                    className="action-button"
                    onClick={() => setRemovingProduct(productId)}
                  >
                    <Trash2 size={16} />
                  </button>
  
                  {removingProduct === productId && (
                    <div className="confirm-remove">
                      <span className="text-sm">Remove?</span>
                      <button 
                        className="confirm-button confirm-yes"
                        onClick={() => {
                          removeFromOrder(productId, sidebarType);
                          setRemovingProduct(null);
                        }}
                      >
                        Yes
                      </button>
                      <button 
                        className="confirm-button confirm-no"
                        onClick={() => setRemovingProduct(null)}
                      >
                        No
                      </button>
                    </div>
                  )}
                  </div>
                </>
              )}
            </div>
          );
        })}

        {/* Footer */}
        <div className="sidebar-footer">
          <div className="total-section">
            <span className="total-label">Total:</span>
            <span className="total-amount">${formatPrice(calculateTotal())}</span>
          </div>

          <button
            className="create-order-button"
            onClick={createOrder}
            disabled={isLoading || Object.keys(order).length === 0}
          >
            Create {sidebarType === 'sales' ? 'Sales' : 'Purchase'} Order
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default EnhancedOrderSidebar;